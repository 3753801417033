import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
} from 'recharts';

import { COLORS } from '../../const/colors';
import { formatBigNumber, formatPercent } from '../../util';

const LINE_NAMES = {
  CLICKS: 'Clicks',
  IMPRESSIONS: 'Impressions',
  CTR: 'CTR',
};
const Y_AXIS_TOP_MARGIN = 1.5;
const TICKS_NUMBER = 4;

const calculateYAxisTicks = (data, key) => {
  const validValues = data.map((i) => i[key]).filter((i) => i > 0) || [];
  const avgValue = validValues.reduce((acc, i) => acc + i, 0) / validValues.length;
  const maxValue = Math.max(...validValues);
  const maxTick = avgValue * Y_AXIS_TOP_MARGIN > maxValue ? avgValue * Y_AXIS_TOP_MARGIN : maxValue;

  return Array(TICKS_NUMBER)
    .fill(0)
    .map((__, idx) => (maxTick / TICKS_NUMBER) * (idx + 1));
};

export default function MultiLineChart({ data = [] }) {
  const viewsYAxisTicks = calculateYAxisTicks(data, 'view_count');
  const clicksYAxisTicks = calculateYAxisTicks(data, 'click_count');
  const ctrYAxisTicks = calculateYAxisTicks(data, 'ctr');

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{
          top: 30,
          right: 40,
          left: 40,
          bottom: 30,
        }}
      >
        <CartesianGrid stroke="#D3D3D3" vertical={false} />
        <XAxis dataKey="date" tickLine={false} tickMargin={5} interval="preserveStart" />
        <Tooltip
          formatter={(value, name) => {
            if (name === LINE_NAMES.CTR) {
              return formatPercent(value * 100);
            }
            return formatBigNumber(value);
          }}
        />
        <Line
          yAxisId="one" // we can use random ids here but they should be different for all lines
          type="linear"
          dataKey="view_count"
          stroke={COLORS.IMPRESSIONS}
          dot={false}
          strokeWidth={2}
          name={LINE_NAMES.IMPRESSIONS}
        />
        <Line
          yAxisId="two"
          type="linear"
          dataKey="click_count"
          stroke={COLORS.CLICKS}
          dot={false}
          strokeWidth={2}
          name={LINE_NAMES.CLICKS}
        />
        <Line
          yAxisId="three"
          type="linear"
          dataKey="ctr"
          stroke={COLORS.CTR}
          dot={false}
          strokeWidth={2}
          name={LINE_NAMES.CTR}
        />
        <YAxis yAxisId="one" hide ticks={viewsYAxisTicks} />
        <YAxis yAxisId="two" hide ticks={clicksYAxisTicks} />
        <YAxis yAxisId="three" hide ticks={ctrYAxisTicks} />
      </LineChart>
    </ResponsiveContainer>
  );
}
