import {
  TEMPLATE_1,
  TEMPLATE_2,
  TEMPLATE_3,
  TEMPLATE_4,
  ctaWizardFields,
} from '../../../const/CtaWizardConsts';

export const WIZARD_SECTION = {
  STEP_2: 'STEP_2',
  STEP_2_PER_PRODUCT: 'STEP_2_PER_PRODUCT',
  STEP_3: 'STEP_3',
};

export const WIZARD_TEMPLATE_CONFIG = {
  [TEMPLATE_1]: {
    [WIZARD_SECTION.STEP_2]: [
      ctaWizardFields.productNameT1,
      ctaWizardFields.smallDescription,
      ctaWizardFields.descriptionT1,
      ctaWizardFields.callToAction,
      ctaWizardFields.colorPickers,
      ctaWizardFields.imageUploader,
    ],
    [WIZARD_SECTION.STEP_3]: [
      ctaWizardFields.creativeName,
      ctaWizardFields.domains,
      ctaWizardFields.pageUrl,
    ],
  },
  [TEMPLATE_2]: {
    [WIZARD_SECTION.STEP_2]: [
      ctaWizardFields.headlineT2,
      ctaWizardFields.brandName,
      ctaWizardFields.productNameT2,
      ctaWizardFields.promo,
      ctaWizardFields.callToAction,
      ctaWizardFields.colorPickers,
      ctaWizardFields.imageUploader,
      ctaWizardFields.pronsTitle,
      ctaWizardFields.prons,
      ctaWizardFields.consTitle,
      ctaWizardFields.cons,
    ],
    [WIZARD_SECTION.STEP_3]: [
      ctaWizardFields.creativeName,
      ctaWizardFields.domains,
      ctaWizardFields.pageUrl,
    ],
  },
  [TEMPLATE_3]: {
    [WIZARD_SECTION.STEP_2]: [
      ctaWizardFields.overallHeading,
      ctaWizardFields.colorPickersForProducts,
    ],
    [WIZARD_SECTION.STEP_2_PER_PRODUCT]: [
      ctaWizardFields.headlineT3,
      ctaWizardFields.productNameT3,
      ctaWizardFields.descriptionT3,
      ctaWizardFields.callToActionPerProduct,
      ctaWizardFields.imageUploaderT3,
    ],
    [WIZARD_SECTION.STEP_3]: [
      ctaWizardFields.creativeName,
      ctaWizardFields.domains,
      ctaWizardFields.pageUrlPerProduct,
    ],
  },
  [TEMPLATE_4]: {
    [WIZARD_SECTION.STEP_2]: [
      ctaWizardFields.overallHeadingOptional,
      ctaWizardFields.colorPickersForProducts,
    ],
    [WIZARD_SECTION.STEP_2_PER_PRODUCT]: [
      ctaWizardFields.badgeTitle,
      ctaWizardFields.checklistItem1,
      ctaWizardFields.checklistItem2,
      ctaWizardFields.checklistItem3,
      ctaWizardFields.checklistItem4,
      ctaWizardFields.rating,
      ctaWizardFields.callToActionPerProduct,
      ctaWizardFields.belowButtonText,
      ctaWizardFields.imageUploaderT4,
    ],
    [WIZARD_SECTION.STEP_3]: [
      ctaWizardFields.creativeName,
      ctaWizardFields.domains,
      ctaWizardFields.pageUrlPerProduct,
    ],
  },
};
