import styled from 'styled-components';
import TemplateOne from '../../features/templates/TemplateOne';
import TemplateTwo from '../../features/templates/TemplateTwo';
import TemplateThree from '../../features/templates/TemplateThree';
import StyledHeading from '../Headline/StyledHeading';
import { TEMPLATE_1, TEMPLATE_2, TEMPLATE_3, TEMPLATE_4 } from '../../const/CtaWizardConsts';
import TemplateFour from '../../features/templates/TemplateFour';

const StyledPreview = styled.div`
  margin: 24px 8px 0;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
  position: sticky;
  top: 0px;
`;

const StyledTemplateWrapper = styled.div`
  height: 100%;
`;

export default function CtaWizardPreview({ formValues, currentStep }) {
  const { template } = formValues;
  return (
    <StyledPreview>
      <StyledHeading>Preview Template</StyledHeading>

      <StyledTemplateWrapper>
        {template === TEMPLATE_1 && <TemplateOne data={formValues} />}
        {template === TEMPLATE_2 && <TemplateTwo data={formValues} />}
        {template === TEMPLATE_3 && <TemplateThree data={formValues} />}
        {template === TEMPLATE_4 && <TemplateFour data={formValues} currentStep={currentStep} />}
      </StyledTemplateWrapper>
    </StyledPreview>
  );
}
