import { Card } from 'antd';
import './style.css';

import templateImg from '../../../assets/templates/templateOneImageAlt.png';
import { VALIDATION_PATTERNS } from '../../../const/validationPatterns';
import { windowOpen } from '../../../util';

const placeholder = {
  headline: 'Product Name',
  smallDescription: 'Product Value Proposition',
  description:
    'This is the product description summary. You can use this to showcase the key features of a product to help educate your audience and improve the conversion rate',
  callToAction: 'Learn More',
  img: templateImg,
};

export default function TemplateOne({ data, width = 'auto' }) {
  const image = data?.fileList?.length > 0 ? data.fileList[0].url : null;

  function handleClick() {
    if (data?.pageUrl?.match(VALIDATION_PATTERNS.URL)) {
      windowOpen(data.pageUrl, '_blank');
    }
  }

  return (
    <Card
      bordered={false}
      style={{ width, maxWidth: '832px', margin: 'auto' }}
      bodyStyle={{ padding: 0 }}
    >
      <div id="template-card-1" className="template-card">
        <div className="headline">
          <span style={{ color: data.headlineColor }}>{data.headline || placeholder.headline}</span>
        </div>
        <div className="small-description">
          {data.smallDescription || placeholder.smallDescription}
        </div>
        <div className="card-content">
          <div className="card-content__image-wrapper">
            <img className="card-content__image" src={image || placeholder.img} alt="image" />
          </div>

          <div className="card-content__aside">
            <p className="paragraph">{data.description || placeholder.description}</p>
            <button
              className="template-button"
              onClick={handleClick}
              style={{
                backgroundColor: data.buttonColor,
                color: data.textButtonColor,
                borderColor: data.buttonColor,
              }}
            >
              {data.callToAction || placeholder.callToAction}
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
}
