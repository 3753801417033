import { ctaWizardFields } from '../../../const/CtaWizardConsts';
import { FIELD_TYPE, WIZARD_FIELD_CONFIG } from '../wizardConfig/fieldConfig';
import UrlPerProductFormItems from './UrlPerProductFormItems';
import RatingFormItem from './RatingFormItem';
import InputFormItem from './InputFormItem';
import TextAreaFormItem from './TextAreaFormItem';
import TagsFormItem from './TagsFormItem';
import ColorPickersFormItem from './ColorPickersFormItem';
import UploadImageFormItem from './UploadImageFormItem';

const getFieldName = ({ defaultName, customName, namespace }) => {
  const name = customName ?? defaultName;
  return namespace ? [namespace, name] : name;
};

export default function WizardFormItem({
  name,
  namespace,
  productAmount,
  formValues,
  setFormValues,
  colorPickersProps,
  imageUploaderProps,
}) {
  if (name === ctaWizardFields.pageUrlPerProduct && productAmount) {
    return <UrlPerProductFormItems productAmount={productAmount} />;
  }

  const fieldConfig = WIZARD_FIELD_CONFIG[name];
  if (!fieldConfig) return null;

  const { fieldType, name: customName } = fieldConfig;
  const fieldName = getFieldName({ defaultName: name, customName, namespace });

  if (fieldType === FIELD_TYPE.INPUT) {
    return <InputFormItem {...fieldConfig} name={fieldName} />;
  }

  if (fieldType === FIELD_TYPE.TEXT_AREA) {
    return <TextAreaFormItem {...fieldConfig} name={fieldName} />;
  }

  if (fieldType === FIELD_TYPE.TAGS) {
    return <TagsFormItem {...fieldConfig} name={fieldName} />;
  }

  if (fieldType === FIELD_TYPE.RATING) {
    return <RatingFormItem {...fieldConfig} name={fieldName} />;
  }

  if (fieldType === FIELD_TYPE.COLOR_PICKERS && formValues && colorPickersProps) {
    return (
      <ColorPickersFormItem
        formValues={formValues}
        setFormValues={setFormValues}
        {...colorPickersProps}
        {...fieldConfig}
      />
    );
  }

  if (fieldType === FIELD_TYPE.IMAGE_UPLOADER && imageUploaderProps) {
    return <UploadImageFormItem {...imageUploaderProps} {...fieldConfig} />;
  }

  return null;
}
