import { DeleteOutlined, PlusCircleOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Card, Form, message, Popover } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import {
  ctaWidgetCardStyles,
  ctaWizardFields,
  MAX_PRODUCT_AMOUNT,
  PRODUCT_1,
  AVAILABLE_PRODUCTS_ARRAY,
} from '../../../../const/CtaWizardConsts';
import { removeProductFromFormValues } from '../../../../util/CtaWizardUtil/CtaWizardUtil';
import { WIZARD_SECTION } from '../../wizardConfig/templateConfig';
import WizardFormItems from '../../WizardFormItems/WizardFormItems';

const StyledProductDropdown = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
  padding-right: 8px;
  border-width: 1px 0px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.06);
  border-radius: 2px 2px 0px 0px;
  margin: 0px -24px;
  padding: 0px 24px;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
  cursor: pointer;
`;

const StyledProductFields = styled.div`
  padding: 24px 0px;
`;

const StyledDropdownIconsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export default function Step2ProductsForm({
  form,
  formValues,
  onFinish,
  setFormValues,
  productAmount,
  setProductAmount,
}) {
  const isMaxProductsAmount = productAmount === MAX_PRODUCT_AMOUNT;
  const [shownPicker, setShownPicker] = useState(null);
  const [openedProductKeysDropdowns, setOpenedProductKeysDropdowns] = useState([PRODUCT_1]);
  const [openedDeletePopover, setOpenedDeletePopover] = useState(null);
  const isDropDownOpen = (productKey) => openedProductKeysDropdowns.includes(productKey);

  function handleProductDropDown(productIndex) {
    setShownPicker(null);
    if (openedProductKeysDropdowns.includes(productIndex)) {
      setOpenedProductKeysDropdowns(openedProductKeysDropdowns.filter((i) => i !== productIndex));
    } else {
      setOpenedProductKeysDropdowns([...openedProductKeysDropdowns, productIndex]);
    }
  }

  function handleAddProduct() {
    if (productAmount < MAX_PRODUCT_AMOUNT) {
      setProductAmount((amount) => {
        const newAmount = amount + 1;
        const productKey = 'product' + newAmount;
        setFormValues((formValues) => ({
          ...formValues,
          [productKey]: {},
        }));
        setOpenedProductKeysDropdowns((productKeys) => [...productKeys, productKey]);
        return newAmount;
      });
    }
  }

  function handleDeleteProductByIndex(e, productIndex) {
    e.stopPropagation();
    new Promise((resolve) => {
      setFormValues((values) => {
        const newValues = removeProductFromFormValues(values, productIndex);
        return newValues;
      });
      setProductAmount((v) => v - 1);
      resolve();
    }).then(() => {
      form.resetFields();
      setOpenedDeletePopover(null);
    });
  }
  return (
    <Form
      form={form}
      layout="vertical"
      name="cta-wizard"
      style={{ display: 'table' }}
      onFinish={onFinish}
      initialValues={{
        ...formValues,
      }}
      onValuesChange={(updateItem) => {
        setFormValues((formValues) => {
          if (ctaWizardFields.overallHeading in updateItem) {
            return {
              ...formValues,
              ...updateItem,
            };
          }

          let newFormValues = {
            ...formValues,
          };
          AVAILABLE_PRODUCTS_ARRAY.forEach((productKey) => {
            if (updateItem[productKey]) {
              newFormValues = {
                ...newFormValues,
                [productKey]: {
                  ...newFormValues[productKey],
                  ...updateItem[productKey],
                },
              };
            }
          });

          return newFormValues;
        });
      }}
      onFinishFailed={(e) => {
        message.error(e.errorFields[0].errors[0]);
      }}
    >
      <Card title="Edit The Copy & Design" bordered={false} style={ctaWidgetCardStyles}>
        <div style={{ background: 'white' }}></div>

        <WizardFormItems
          templateId={formValues.template}
          wizardSection={WIZARD_SECTION.STEP_2}
          formValues={formValues}
          setFormValues={setFormValues}
          colorPickersProps={{
            shownPicker,
            setShownPicker,
            bottomPosition: true,
          }}
        />

        {Array(productAmount)
          .fill(true)
          .map((_, productIndex) => {
            const productNumber = productIndex + 1;
            const productKey = 'product' + productNumber;
            return (
              <div key={productKey}>
                <StyledProductDropdown onClick={() => handleProductDropDown(productKey)}>
                  <p>Product {productNumber}</p>
                  <StyledDropdownIconsWrapper>
                    <UpOutlined rotate={isDropDownOpen(productKey) ? 0 : 180} />
                    {productAmount > 1 && (
                      <Popover
                        content={
                          <div style={{ display: 'flex', gap: '4px' }}>
                            <Button
                              danger={true}
                              onClick={(e) => handleDeleteProductByIndex(e, productIndex)}
                              type="primary"
                            >
                              Delete
                            </Button>
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenedDeletePopover(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        }
                        title={'Delete this product?'}
                        trigger="click"
                        open={openedDeletePopover === productKey}
                        placement="leftTop"
                      >
                        <DeleteOutlined
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenedDeletePopover((value) =>
                              value === productKey ? null : productKey
                            );
                          }}
                        />
                      </Popover>
                    )}
                  </StyledDropdownIconsWrapper>
                </StyledProductDropdown>
                {isDropDownOpen(productKey) && (
                  <StyledProductFields>
                    <WizardFormItems
                      templateId={formValues.template}
                      wizardSection={WIZARD_SECTION.STEP_2_PER_PRODUCT}
                      namespace={productKey}
                      imageUploaderProps={{
                        initFileList: formValues[productKey].fileList,
                        updateFileList: (fileList) => {
                          setFormValues((values) => ({
                            ...values,
                            [productKey]: {
                              ...values[productKey],
                              fileList: fileList ? [...fileList] : undefined,
                            },
                          }));
                        },
                      }}
                    />
                  </StyledProductFields>
                )}
              </div>
            );
          })}

        <Button
          style={{
            marginTop: 24,
          }}
          disabled={isMaxProductsAmount}
          type={'primary'}
          onClick={handleAddProduct}
        >
          <PlusCircleOutlined /> Add Product
        </Button>
      </Card>
    </Form>
  );
}
