import { Form, Input } from 'antd';

import { pageUrlPlaceholder, pageUrlRules } from '../../../const/CtaWizardConsts';

function UrlFormItem({ itemNumber }) {
  return (
    <Form.Item
      label={`Product ${itemNumber} Landing Page URL (affiliate tracking link)`}
      name={[`product${itemNumber}`, 'pageUrl']}
      rules={pageUrlRules}
    >
      <Input placeholder={pageUrlPlaceholder} />
    </Form.Item>
  );
}

export default function UrlPerProductFormItems({ productAmount }) {
  return new Array(productAmount)
    .fill(0)
    .map((__, idx) => <UrlFormItem key={idx} itemNumber={idx + 1} />);
}
