import Loading from '../../components/Loading/Loading';
import MultiLineChart from '../../features/MultiLineChart';
import Tabs from '../../features/MultiLineChart/Tabs';

export default function AnalyticsChart({ tabsData = {}, chartData = [], isLoading = false }) {
  return (
    <div
      style={{
        boxShadow: `0px 2px 8px rgba(0, 0, 0, 0.15)`,
        borderRadius: '25px',
        background: 'white',
        height: isLoading ? '600px' : 'auto',
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Tabs data={tabsData} />
          <div style={{ height: '500px' }}>
            <MultiLineChart data={chartData} />
          </div>
        </>
      )}
    </div>
  );
}
