import { VALIDATION_PATTERNS } from './validationPatterns';

export const TEMPLATE_1 = '1';
export const TEMPLATE_2 = '2';
export const TEMPLATE_3 = '3';
export const TEMPLATE_4 = '4';

export const ctaWizardFields = {
  template: 'template',
  headline: 'headline',
  headlineT2: 'headlineT2',
  headlineT3: 'headlineT3',

  smallDescription: 'smallDescription',

  description: 'description',
  descriptionT1: 'descriptionT1',
  descriptionT3: 'descriptionT3',

  callToAction: 'callToAction',
  callToActionPerProduct: 'callToActionPerProduct',
  buttonColor: 'buttonColor',
  textButtonColor: 'textButtonColor',
  headlineColor: 'headlineColor',
  creativeName: 'creativeName',
  domains: 'domains',

  brandName: 'brandName',
  productName: 'productName',
  productNameT1: 'productNameT1',
  productNameT2: 'productNameT2',
  productNameT3: 'productNameT3',
  promo: 'promo',

  pronsTitle: 'pronsTitle',
  prons: 'prons',
  consTitle: 'consTitle',
  cons: 'cons',

  pageUrl: 'pageUrl',
  pageUrlPerProduct: 'pageUrlPerProduct',

  overallHeading: 'overallHeading',
  overallHeadingOptional: 'overallHeadingOptional',
  fileList: 'fileList',

  badgeTitle: 'badgeTitle',
  checklistItem1: 'checklistItem1',
  checklistItem2: 'checklistItem2',
  checklistItem3: 'checklistItem3',
  checklistItem4: 'checklistItem4',
  rating: 'rating',
  belowButtonText: 'belowButtonText',

  colorPickers: 'colorPickers',
  colorPickersForProducts: 'colorPickersForProducts',
  imageUploader: 'imageUploader',
  imageUploaderT3: 'imageUploaderT3',
  imageUploaderT4: 'imageUploaderT4',
};

export const PRODUCT_1 = 'product1';
export const MAX_PRODUCT_AMOUNT = 3;
export const AVAILABLE_PRODUCTS_ARRAY = new Array(MAX_PRODUCT_AMOUNT)
  .fill(0)
  .map((__, idx) => `product${idx + 1}`);

export const HEADLINE_COLOR = 'headlineColor';
export const TEXT_BUTTON_COLOR = 'textButtonColor';
export const BUTTON_COLOR = 'buttonColor';

export const ctaWidgetCardStyles = {
  width: '448px',
  background: 'white',
  marginBottom: '12px',
  minHeight: '700px',
};

export const ctaWizardDefaultColors = {
  headlineColor: '#000000',
  buttonColor: '#368DFF',
  textButtonColor: '#FFFFFF',
  blue: '#368DFF',
  black: '#000000',
};

export const emptyWidgetInitialValues = {
  description: null,
  prefix: '1',
  headlineColor: ctaWizardDefaultColors.headlineColor,
  textButtonColor: ctaWizardDefaultColors.textButtonColor,
  buttonColor: ctaWizardDefaultColors.buttonColor,
  template: TEMPLATE_1,
  callToAction: '',
  prons: [],
  cons: [],
  product1: {},
  pronsTitle: 'Pros',
  consTitle: 'Cons',
  [ctaWizardFields.promo]: '',
};

export const MAX_SELECT_TAGS_COUNT = 3;

export const ALLOWED_IMAGE_FORMATS = [
  { name: 'PNG', type: 'image/png' },
  { name: 'JPEG', type: 'image/jpeg' },
  { name: 'JPG', type: 'image/jpg' },
  { name: 'SVG', type: 'image/svg+xml' },
  { name: 'WEBP', type: 'image/webp' },
];

export const TOO_LARGE_NUMBER = 1000000000;

export const pageUrlPlaceholder = 'http://www.cj.com/offerID123 (+ preferred subIDs)';
export const pageUrlRules = [
  {
    required: true,
    message:
      'Please input the landing page URL (be sure to include tracking parameters and preferred subIDs).',
  },
  {
    pattern: VALIDATION_PATTERNS.URL,
    message:
      'Product Landing Page URL is not valid, be sure to include http:// or https://, tracking parameters, and preferred subIDs.',
  },
];
