import { Button, Card, Table } from 'antd';

import CtaTableActionCell from './CtaTableActionCell';
import { PlusCircleOutlined } from '@ant-design/icons';
import StyledHeading from '../../components/Headline/StyledHeading';
import StyledTableHeader from '../../components/Table/StyledTableHeader';
import { useContext } from 'react';
import { NotificationContext } from '../../context/NotificationContext';
import { TOO_LARGE_NUMBER } from '../../const/CtaWizardConsts';
import CtaTableDomainCell from './CtaTableDomainCell';

const columnsIndexes = {
  name: 'name',
  domains: 'domains',
  impressions: 'impressions',
  clicks: 'clicks',
  actions: 'actions',
};

const columns = [
  {
    title: 'Creative Name',
    dataIndex: columnsIndexes.name,
    sorter: (a, b) => a.name.length - b.name.length,
    width: '30%',
  },
  {
    title: 'Approved Websites',
    dataIndex: columnsIndexes.domains,
    width: '40%',
    render: (_, rowData) => <CtaTableDomainCell data={rowData} />,
  },
  {
    title: 'Views',
    dataIndex: columnsIndexes.impressions,
    sorter: (a, b) => a.impressions - b.impressions,
  },
  {
    title: 'Clicks',
    dataIndex: columnsIndexes.clicks,
    sorter: (a, b) => a.clicks - b.clicks,
  },
  {
    title: 'Actions',
    dataIndex: '',
    key: 'x',
    render: (rowData) => <CtaTableActionCell row={rowData} />,
  },
];
export default function CtaList({ onCreateButtonClick, data }) {
  const { notificationNode } = useContext(NotificationContext);

  return (
    <Card className="cta-list" bordered={false}>
      {notificationNode}
      <Table
        className="ant-table-container"
        title={() => (
          <StyledTableHeader>
            <StyledHeading>
              Click below to get code, clone, edit, or remove your saved CTAs
            </StyledHeading>
            <Button type="primary" icon={<PlusCircleOutlined />} onClick={onCreateButtonClick}>
              Create new
            </Button>
          </StyledTableHeader>
        )}
        columns={columns}
        dataSource={data}
        style={{ width: '100%' }}
        pagination={{
          position: ['none', 'none'],
          pageSize: TOO_LARGE_NUMBER,
        }}
        scroll={{
          x: '800px',
        }}
      />
    </Card>
  );
}
