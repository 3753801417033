import { Select } from 'antd';

import InputLabel from '../Label/InputLabel';

export default function Selector({
  options,
  label,
  value,
  onChange,
  isLoading = false,
  placeholder = null,
  size = 'large',
}) {
  return (
    <>
      <InputLabel text={label} />
      <Select
        size={size}
        style={{ width: '100%' }}
        options={options}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        loading={isLoading}
      />
    </>
  );
}
