import {
  CheckCircleFilled,
  CodeOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ActionConfirmPopover from '../../components/Popover/ActionConfirmPopover';
import { CtaListContext } from '../../context/CtaListContext';
import { NotificationContext } from '../../context/NotificationContext';
import { copyWidgetCode } from '../../util/CtaWizardUtil/CtaWizardUtil';

const StyledIconsWrapper = styled.div`
  display: flex;
  gap: 16px;
  lex-wrap: wrap;
`;

export default function CtaTableActionCell({ row }) {
  const widgetID = row.key;

  const { deleteWidget, cloneWidget } = useContext(CtaListContext);

  const { notificationApi } = useContext(NotificationContext);
  const navigate = useNavigate();

  function handleEditWidget() {
    navigate(`/widgets/edit/${widgetID}`);
  }

  function showSuccessNotification(message) {
    notificationApi.info({
      message: message,
      placement: 'bottomRight',
      icon: <CheckCircleFilled style={{ color: '#52C41A' }} />,
    });
  }

  async function handleDeleteWidget() {
    try {
      await deleteWidget(widgetID);
      showSuccessNotification('The CTA has been successfully deleted!');
    } catch (error) {
      console.error(error);
    }
  }

  async function handleCloneWidget() {
    try {
      await cloneWidget(widgetID);
      showSuccessNotification('The CTA has been successfully cloned!');
    } catch (error) {
      console.error(error);
    }
  }

  function handleCopyWidgetCode(widgetID) {
    copyWidgetCode(widgetID);
    showSuccessNotification('The CTA code has been successfully copied!');
  }

  return (
    <StyledIconsWrapper>
      <Tooltip title="Copy code">
        <CodeOutlined onClick={() => handleCopyWidgetCode(widgetID)} />
      </Tooltip>
      <ActionConfirmPopover
        handleConfirm={handleCloneWidget}
        text="Are you sure you want to clone this CTA? This will create an exact duplicate and not impact the existing CTA in any way."
        confirmText="Clone"
        rejectText="Back"
      >
        <Tooltip title="Clone">
          <CopyOutlined />
        </Tooltip>
      </ActionConfirmPopover>
      <Tooltip title="Edit">
        <EditOutlined onClick={handleEditWidget} />
      </Tooltip>
      <ActionConfirmPopover
        handleConfirm={handleDeleteWidget}
        text="Are you sure you want to delete this CTA? Once you click delete you will not be able to recover your CTA. Any live placements using this code will no longer render once you click delete."
        isConfirmButtonDanger
        confirmText="Delete"
        rejectText="Back"
      >
        <Tooltip title="Delete">
          <DeleteOutlined />
        </Tooltip>
      </ActionConfirmPopover>
    </StyledIconsWrapper>
  );
}
