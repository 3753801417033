export const CtaListContext = React.createContext();

import React, { useContext, useEffect, useState } from 'react';
import useImpersonate from '../hooks/useImpersonate';
import useMutation from '../hooks/useMutation';
import useWidgetData from '../pages/CtaList/useWidgetData';
import { convertDataForCtaTable, convertItemForCtaTable } from '../util/ctaListUtil/ctaListUtil';
import { AdminAccountContext } from './AdminAccountContext';

export default function CtaListContextProvider({ children }) {
  const { selectedAdminAccount, isAdmin } = useContext(AdminAccountContext);
  const impersonateUrl = useImpersonate();

  const {
    widgetData,
    widgetError: getWidgetsError,
    isWidgetsLoading,
  } = useWidgetData({ selectedAdminAccount, isAdmin });
  const [widgets, setWidgets] = useState(null);
  const [deleteWidgetMutation] = useMutation({ method: 'delete' });
  const [cloneWidgetMutation] = useMutation({ method: 'post' });
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (widgetData) {
      const initialCtaListItems = !widgetData ? null : convertDataForCtaTable(widgetData);
      setWidgets(initialCtaListItems);
    }
  }, [widgetData]);

  useEffect(() => {
    setIsUpdating(false);
  }, [widgets]);

  async function deleteWidget(widgetID) {
    try {
      await deleteWidgetMutation({ url: impersonateUrl(`/api/v1/widgets/${widgetID}`) });
      setWidgets((oldWidgets) => {
        const newWidgets = oldWidgets.filter((widget) => widget.key !== widgetID);
        return newWidgets;
      });
    } catch (error) {
      throw new Error(error);
    }
  }

  async function cloneWidget(widgetID) {
    setIsUpdating(true);
    try {
      const clonedWidget = await cloneWidgetMutation({
        url: impersonateUrl(`/api/v1/widgets/${widgetID}`),
      });

      setWidgets((oldWidgets) => {
        const newWidget = convertItemForCtaTable(clonedWidget.item);
        return [newWidget, ...oldWidgets];
      });
    } catch (error) {
      setIsUpdating(false);
      throw new Error(error);
    }
  }

  return (
    <CtaListContext.Provider
      value={{
        widgets,
        isWidgetsLoading,
        getWidgetsError,
        setWidgets,
        deleteWidget,
        cloneWidget,
        isUpdating,
      }}
    >
      {children}
    </CtaListContext.Provider>
  );
}
