import { Form } from 'antd';

import UploadImage from '../UploadImage';

export default function UploadImageFormItem({ label, initFileList, updateFileList, hint }) {
  return (
    <Form.Item label={label} required>
      <UploadImage initFileList={initFileList} updateFileList={updateFileList} hint={hint} />
    </Form.Item>
  );
}
