import { Card, Form } from 'antd';
import { keys } from 'lodash';

import {
  AVAILABLE_PRODUCTS_ARRAY,
  ctaWidgetCardStyles,
  ctaWizardFields,
} from '../../../const/CtaWizardConsts';
import { isProductsTemplate } from '../../../util/CtaWizardUtil/CtaWizardUtil';
import { WIZARD_SECTION } from '../wizardConfig/templateConfig';
import WizardFormItems from '../WizardFormItems/WizardFormItems';

export default function Step3Form({ form, formValues, setFormValues, onFinish, productAmount }) {
  const { template } = formValues;
  return (
    <Form
      form={form}
      layout="vertical"
      name="cta-wizard"
      onFinish={onFinish}
      initialValues={formValues}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      onValuesChange={(updateItem) => {
        if (!isProductsTemplate(template)) {
          setFormValues((formVal) => ({
            ...formVal,
            ...updateItem,
          }));
          return;
        }

        setFormValues((formValues) => {
          const propToUpdate = keys(updateItem)[0];
          if ([ctaWizardFields.creativeName, ctaWizardFields.domains].includes(propToUpdate)) {
            return {
              ...formValues,
              ...updateItem,
            };
          }

          let newFormValues = {
            ...formValues,
          };
          AVAILABLE_PRODUCTS_ARRAY.forEach((productKey) => {
            if (updateItem[productKey]) {
              newFormValues = {
                ...newFormValues,
                [productKey]: {
                  ...newFormValues[productKey],
                  ...updateItem[productKey],
                },
              };
            }
          });

          return newFormValues;
        });
      }}
    >
      <Card title="Configure Creative Details" bordered={false} style={ctaWidgetCardStyles}>
        <WizardFormItems
          templateId={formValues.template}
          wizardSection={WIZARD_SECTION.STEP_3}
          productAmount={productAmount}
        />
      </Card>
    </Form>
  );
}
