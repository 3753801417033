import { Button, Card, Empty, Layout, message, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

import routes from '../../const/routes';
import CtaList from '../../features/CtaList';
import { useContext } from 'react';
import { CtaListContext } from '../../context/CtaListContext';
import Loading from '../../components/Loading/Loading';

export default function CtaListPage() {
  const navigate = useNavigate();

  const { widgets, getWidgetsError, isUpdating, isWidgetsLoading } = useContext(CtaListContext);

  if (getWidgetsError) {
    message.error('Failed to fetch widgets');
  }
  if (isWidgetsLoading || !widgets)
    return (
      <Layout.Content style={{ padding: '24px', overflowY: 'scroll' }}>
        <Card
          style={{
            height: '100%',
            display: 'grid',
            alignContent: 'center',
          }}
        >
          <Loading />
        </Card>
      </Layout.Content>
    );

  function handleCreateNewWidget() {
    navigate(routes.CTA_WIZARD);
  }

  return (
    <Layout.Content style={{ padding: '24px', overflowY: 'scroll' }}>
      {widgets.length ? (
        <Spin spinning={isUpdating}>
          <CtaList onCreateButtonClick={handleCreateNewWidget} data={widgets} />
        </Spin>
      ) : (
        <Card
          style={{
            height: '100%',
            display: 'grid',
            alignContent: 'center',
          }}
        >
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>Get Started By Creating Your First CTA</span>}
          >
            <Button type="primary" onClick={handleCreateNewWidget}>
              Create New
            </Button>
          </Empty>
        </Card>
      )}
    </Layout.Content>
  );
}
