import styled from 'styled-components';

const StyledTableHeader = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export default StyledTableHeader;
