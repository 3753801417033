import React from 'react';
import { notification } from 'antd';

export const NotificationContext = React.createContext();

export default function NotificationContextProvider({ children }) {
  const [notificationApi, notificationNode] = notification.useNotification();

  return (
    <NotificationContext.Provider value={{ notificationNode, notificationApi }}>
      {children}
    </NotificationContext.Provider>
  );
}
