const CTA_LIST = '/widgets';
const CTA_WIZARD = '/widgets/add';
const CTA_WIZARD_EDIT = '/widgets/edit/:id';
const ANALYTICS = '/analytics';

const PAGE_NOT_FOUND = '/404';
const LOGOUT = '/logout';

const GET_WIDGETS_DATA = '/api/v1/widgets';

export default {
  CTA_LIST,
  CTA_WIZARD,
  ANALYTICS,
  PAGE_NOT_FOUND,
  LOGOUT,
  CTA_WIZARD_EDIT,
  GET_WIDGETS_DATA,
};
