import { Table, Tabs } from 'antd';
import { useState } from 'react';

import StyledTable from '../../components/Table/StyledTable';
import { COLORS } from '../../const/colors';
import { formatPercent } from '../../util';

const TABS = {
  domain: { key: 'domain', label: 'Websites', columnLabel: 'Website' },
  country: { key: 'country', label: 'Countries', columnLabel: 'Country' },
  device_type: { key: 'device_type', label: 'Device Type', columnLabel: 'Device Type' },
};

const getColumns = (activeTab) => [
  {
    title: TABS[activeTab]?.columnLabel || '',
    dataIndex: 'value',
  },
  {
    title: 'Clicks',
    dataIndex: 'click_count',
    sorter: (a, b) => a.click_count - b.click_count,
    render: (text) => <span style={{ color: COLORS.CLICKS }}>{text.toLocaleString('en')}</span>,
  },
  {
    title: 'Views',
    dataIndex: 'view_count',
    sorter: (a, b) => a.view_count - b.view_count,
    render: (text) => (
      <span style={{ color: COLORS.IMPRESSIONS }}>{text.toLocaleString('en')}</span>
    ),
  },
  {
    title: 'CTR',
    dataIndex: 'ctr',
    sorter: (a, b) => a.ctr - b.ctr,
    render: (text) => (
      <span style={{ color: COLORS.CTR }}>{formatPercent(parseFloat(text) * 100)}</span>
    ),
  },
];

export default function AnalyticsTable({ data = {}, isLoading = false }) {
  const [activeTab, setActiveTab] = useState(TABS.domain.key);

  const dataSource = data[activeTab]?.map((i) => ({ ...i, key: i.value })) || [];

  return (
    <StyledTable>
      <Table
        className="ant-table-container"
        loading={isLoading}
        title={() => (
          <Tabs
            className="ant-tabs-container"
            items={[TABS.domain, TABS.country, TABS.device_type]}
            activeKey={activeTab}
            onChange={setActiveTab}
            size="large"
            style={{ fontWeight: 'bold' }}
          />
        )}
        columns={getColumns(activeTab)}
        dataSource={dataSource}
        style={{ width: '100%', marginTop: '30px' }}
        pagination={{
          position: ['none', 'none'],
        }}
      />
    </StyledTable>
  );
}
