import { useContext } from 'react';

import { AdminAccountContext } from '../context/AdminAccountContext';

export default function useImpersonate() {
  const { selectedAdminAccount } = useContext(AdminAccountContext);
  const impersonateUrl = (url) => {
    return selectedAdminAccount ? `${url}?actAsAccountId=${selectedAdminAccount}` : url;
  };

  return impersonateUrl;
}
