import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { useState } from 'react';

import { cdnUrl } from '../../const/const';
import { ALLOWED_IMAGE_FORMATS } from '../../const/CtaWizardConsts';
import useImpersonate from '../../hooks/useImpersonate';
import useMutation from '../../hooks/useMutation';

const ALLOWED_IMAGE_FORMATS_NAMES = ALLOWED_IMAGE_FORMATS.map((i) => i.name);

function UploadImage({ updateFileList, initFileList, hint }) {
  const impersonateUrl = useImpersonate();

  const [uploadImage] = useMutation({ baseUrl: '', method: 'put', isWithAccessToken: false });
  const [getUploadImageData] = useMutation({
    url: impersonateUrl('/api/v1/files'),
    method: 'post',
  });
  const [isRemoveButtonShown, setIsRemoveButtonShown] = useState(true);
  const draggerProps = {
    name: 'template image',
    maxCount: 1,
    withCredentials: true,
    crossOrigin: 'use-credentials',
    beforeUpload: (file) => {
      const isAllowedFormat = ALLOWED_IMAGE_FORMATS.some((i) => i.type === file.type);
      if (!isAllowedFormat) {
        message.error(
          `${file.name}(${file.type}) is not a ${ALLOWED_IMAGE_FORMATS_NAMES.map(
            (i) => `.${i}`
          ).join('/')} file`
        );
      }
      return isAllowedFormat || Upload.LIST_IGNORE;
    },
    customRequest: async (info) => {
      setIsRemoveButtonShown(false);
      const { file } = info;
      const imgContentType = info.file.type;
      const extension = file.name.split('.').at(-1);

      const {
        item: { preSignedUrl, path },
      } = await getUploadImageData({
        body: {
          extension,
          contentType: imgContentType,
        },
      });

      await uploadImage({
        url: preSignedUrl,
        body: file,
        headers: {
          'Content-Type': imgContentType,
          'Cache-Control': 'max-age=31536000',
        },
      });
      updateFileList([
        {
          uid: file.uid,
          name: file.name,
          status: file.status,
          url: `${cdnUrl}${path}`,
          thumbUrl: `${cdnUrl}${path}`,
          path,
        },
      ]);
      setIsRemoveButtonShown(true);
    },

    fileList: initFileList,
    onChange: ({ fileList: newFileList }) => {
      updateFileList(newFileList);
    },
    onRemove() {
      updateFileList(undefined);
    },
  };

  return (
    <>
      {!initFileList || initFileList.length === 0 ? (
        <Upload.Dragger {...draggerProps} fileList={initFileList}>
          <>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click Or Drag Image To This Area To Upload</p>

            <p className="ant-upload-hint" style={{ whiteSpace: 'pre' }}>
              {hint}
            </p>
          </>
        </Upload.Dragger>
      ) : (
        <Upload
          listType="picture"
          fileList={initFileList}
          onRemove={draggerProps.onRemove}
          showUploadList={{
            showRemoveIcon: isRemoveButtonShown,
          }}
        ></Upload>
      )}
    </>
  );
}
export default UploadImage;
