import styled from 'styled-components';

import ColorPickerFields from '../StepForms/Step2Form/ColorPickerFields';

const StyledH4 = styled.div`
  padding-bottom: 8px;
  font-weight: 600;
`;

export default function ColorPickersFormItem({ title, formValues, setFormValues, ...customProps }) {
  return (
    <>
      {title && <StyledH4>{title}</StyledH4>}
      <ColorPickerFields formValues={formValues} setFormValues={setFormValues} {...customProps} />
    </>
  );
}
