import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

import { auth0Audience } from '../const/const';

export function getAuth0AuthorizationParams() {
  return {
    audience: auth0Audience,
  };
}

export default function useAccessToken() {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);
  useEffect(() => {
    async function fetchAccessToken() {
      const token = await getAccessTokenSilently({
        authorizationParams: getAuth0AuthorizationParams(),
      });
      setAccessToken(token);
    }

    fetchAccessToken();
  }, []);

  return accessToken;
}
