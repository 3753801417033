import { Card, Form } from 'antd';
import { useState } from 'react';
import { ctaWidgetCardStyles, ctaWizardFields } from '../../../../const/CtaWizardConsts';
import { formatPronsCons } from '../../../../util/CtaWizardUtil/CtaWizardUtil';
import { WIZARD_SECTION } from '../../wizardConfig/templateConfig';
import WizardFormItems from '../../WizardFormItems/WizardFormItems';

export default function Step2Form({ form, formValues, onFinish, setFormValues }) {
  const [shownPicker, setShownPicker] = useState(null);
  return (
    <Form
      form={form}
      layout="vertical"
      name="cta-wizard"
      onFinish={onFinish}
      initialValues={formValues}
      onValuesChange={(updateItem) => {
        if (updateItem[ctaWizardFields.prons]) {
          updateItem[ctaWizardFields.prons] = formatPronsCons(updateItem[ctaWizardFields.prons]);
        }
        if (updateItem[ctaWizardFields.cons]) {
          updateItem[ctaWizardFields.cons] = formatPronsCons(updateItem[ctaWizardFields.cons]);
        }
        setFormValues((formVal) => ({ ...formVal, ...updateItem }));
      }}
    >
      <Card title="Edit The Copy & Design" bordered={false} style={ctaWidgetCardStyles}>
        <WizardFormItems
          templateId={formValues.template}
          wizardSection={WIZARD_SECTION.STEP_2}
          formValues={formValues}
          setFormValues={setFormValues}
          colorPickersProps={{
            shownPicker,
            setShownPicker,
          }}
          imageUploaderProps={{
            initFileList: formValues.fileList,
            updateFileList: (fileList) => {
              setFormValues((vals) => ({
                ...vals,
                fileList: fileList ? [...fileList] : undefined,
              }));
            },
          }}
        />
      </Card>
    </Form>
  );
}
