import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useReducer, useRef } from 'react';

import { apiUrl, REQUEST_RETRY_TIMES } from '../const/const';
import getHttpErrorMessage from '../util/getHttpErrorMessage';
import makeHttpRequest from '../util/makeHttpRequest';
import useAccessToken, { getAuth0AuthorizationParams } from './useAccessToken';

export function useFetch({
  url: genericUrl,
  options,
  isLazy = false,
  baseUrl = apiUrl,
  isNoCache = false,
}) {
  const cache = useRef({});
  let accessToken = useAccessToken();
  const { getAccessTokenSilently } = useAuth0();
  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef(false);
  let retryCount = 0;

  const initialState = {
    error: undefined,
    data: undefined,
    isLoading: false,
  };

  // Keep state logic separated
  const fetchReducer = (state, action) => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, isLoading: true };
      case 'fetched':
        return { ...initialState, data: action.payload };
      case 'error':
        return { ...initialState, error: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const makeRequest = async (url = genericUrl) => {
    if (isLazy && !accessToken) {
      accessToken = await getAccessTokenSilently({
        authorizationParams: getAuth0AuthorizationParams(),
      });
    }

    // Do nothing if the url or access token is not given
    if (!url || !accessToken) return;
    cancelRequest.current = false;

    const fetchData = async (isRetry) => {
      if (isRetry) {
        accessToken = await getAccessTokenSilently({
          authorizationParams: getAuth0AuthorizationParams(),
        });
      }

      dispatch({ type: 'loading' });

      // If a cache exists for this url, return it
      if (cache.current[url] && !isNoCache) {
        dispatch({ type: 'fetched', payload: cache.current[url] });
        return;
      }

      try {
        const data = await makeHttpRequest({
          method: 'get',
          baseUrl,
          url,
          accessToken,
          ...options,
        });

        cache.current[url] = data;
        if (cancelRequest.current) return;

        dispatch({ type: 'fetched', payload: data });
      } catch (error) {
        if (cancelRequest.current) return;

        if (error?.response?.status !== 401) {
          console.error(getHttpErrorMessage(error));
          dispatch({ type: 'error', payload: error });
          return;
        }

        if (retryCount < REQUEST_RETRY_TIMES) {
          retryCount++;
          return fetchData(true);
        }

        console.error(getHttpErrorMessage(error));
        dispatch({ type: 'error', payload: error });
      }
    };

    void fetchData();
  };

  if (isLazy) {
    return [makeRequest, state];
  } else {
    useEffect(() => {
      makeRequest();
      // Use the cleanup function for avoiding a possibly...
      // ...state update after the component was unmounted
      return () => {
        cancelRequest.current = true;
      };
    }, [genericUrl, accessToken]);
    return state;
  }
}
