import { unescape } from 'lodash';
import get from 'lodash.get';
import toPairs from 'lodash.topairs';

const ctaColumnsIndexes = {
  name: 'name',
  domains: 'domains',
  impressions: 'analytics.viewsCount',
  clicks: 'analytics.clicksCount',
  actions: 'actions',
};

export function convertDataForCtaTable(data) {
  let res = [];

  if (!data?.count) {
    return [];
  }
  data.items.forEach((item) => {
    const resItem = {};
    toPairs(ctaColumnsIndexes).forEach(([key, columnPath]) => {
      resItem[key] = get(item, columnPath);
    });
    resItem.name = unescape(resItem.name);
    resItem.key = item.id;
    res = [...res, resItem];
  });
  return res;
}

export function convertItemForCtaTable(item) {
  const resItem = {};
  toPairs(ctaColumnsIndexes).forEach(([key, columnPath]) => {
    resItem[key] = get(item, columnPath);
  });
  resItem.name = unescape(resItem.name);
  resItem.key = item.id;
  return resItem;
}
