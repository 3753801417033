import { Form, Input } from 'antd';

export default function TextAreaFormItem({
  name,
  label,
  rules,
  requiredMark,
  maxLength,
  placeholder,
  isShowCount = true,
}) {
  return (
    <Form.Item label={label} name={name} rules={rules} requiredMark={requiredMark}>
      <Input.TextArea showCount={isShowCount} maxLength={maxLength} placeholder={placeholder} />
    </Form.Item>
  );
}
