import { useContext, useEffect } from 'react';

import Selector from '../../components/Select';
import { AdminAccountContext } from '../../context/AdminAccountContext';
import { useFetch } from '../../hooks/useFetch';

export default function AdminAccountSelector() {
  const { selectedAdminAccount, setSelectedAdminAccount, isAdmin } =
    useContext(AdminAccountContext);

  const [
    getAdminAccounts,
    { data: adminAccountsData, isLoading: isAdminAccountsLoading, error: adminAccountsError },
  ] = useFetch({ url: '/api/v1/admin/accounts', isLazy: true });

  const adminAccountOptions =
    adminAccountsData?.items?.map((i) => ({ value: i.id, label: i.email })) || [];

  useEffect(() => {
    if (isAdmin && !adminAccountsData) {
      getAdminAccounts();
    }
  }, [isAdmin]);

  useEffect(() => {
    if (adminAccountOptions?.length && !selectedAdminAccount) {
      setSelectedAdminAccount(adminAccountOptions[0].value);
    }
  }, [adminAccountOptions]);

  if (!isAdmin || adminAccountsError) {
    return null;
  }

  return (
    <Selector
      options={adminAccountOptions}
      value={selectedAdminAccount}
      onChange={setSelectedAdminAccount}
      isLoading={isAdminAccountsLoading}
      placeholder="Act as User"
    />
  );
}
