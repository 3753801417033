import { RouterProvider } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import './App.css';
import './antDesign.css';
import routes from './const/routes';
import LoadingPage from './pages/Loading/LoadingPage';
import ErrorPopupResult from './components/Popups/ErrorPopupResult';
import AdminAccountContextProvider from './context/AdminAccountContext';
import CelebrationPopupResult from './components/Popups/CelebrationPopupResult';
import router from './router';

function App() {
  const { isAuthenticated, isLoading, loginWithRedirect, error, logout } = useAuth0();

  function handleLogout() {
    logout({ logoutParams: { returnTo: `${window.location.origin}${routes.LOGOUT}` } });
  }

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, error]);

  if (error) {
    if (error?.message?.includes('verify your email')) {
      return (
        <CelebrationPopupResult
          title={'Thank you for signing up!'}
          message={
            'Check your email inbox for a link to set up your account and create your first CTA'
          }
          close={handleLogout}
        />
      );
    }
    return (
      <ErrorPopupResult
        title="Something went wrong. Please try logging in later."
        close={handleLogout}
      />
    );
  }

  if (isLoading || !isAuthenticated) {
    return <LoadingPage />;
  }

  return (
    <AdminAccountContextProvider>
      <RouterProvider router={router} />
    </AdminAccountContextProvider>
  );
}

export default App;
