import dayjs from 'dayjs';

import ANALYTICS_FORMAT from '../const/analyticsFormat';

export const formatPercent = (value) => {
  return `${+parseFloat(value).toFixed(2)}%`;
};

export const formatBigNumber = (value) => {
  if (value > 10000) return `${(+parseFloat(value / 1000)).toFixed(1)}K`;
  if (value > 1000000) return `${(+parseFloat(value / 1000000)).toFixed(2)}M`;
  return value;
};

export const downloadFile = (url) => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const composeAnalyticsUrl = ({
  startDate,
  endDate,
  selectedWidget,
  format = ANALYTICS_FORMAT.JSON,
}) => {
  const startDateFormatted = dayjs(startDate).format('YYYY-MM-DD');
  const endDateFormatted = dayjs(endDate).format('YYYY-MM-DD');

  return `/analytics?from=${startDateFormatted}&to=${endDateFormatted}&widget_id=${selectedWidget}&format=${format}`;
};

export function windowOpen(url, specs) {
  if (!url.match(/^https?:\/\//i)) {
    url = 'http://' + url;
  }
  return window.open(url, specs);
}

function removeSpaces(str) {
  return str.toString().replace(/\s/g, '');
}

export function isWhiteColor(color) {
  if (!color) {
    return false;
  }

  color = removeSpaces(color).toLowerCase();

  if (color.includes('white')) {
    return true;
  }
  if (color === '#fff') {
    return true;
  }

  if (color.includes('#ffffff')) {
    return true;
  }

  if (color.includes('rgb(255,255,255)')) {
    return true;
  }
  if (color.includes('rgba(255,255,255')) {
    return true;
  }

  if (color === 'transparent') {
    return true;
  }

  return false;
}
