import { Button, Layout, Steps, Form, message } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  ctaWizardDefaultColors,
  emptyWidgetInitialValues,
  TEMPLATE_1,
} from '../../const/CtaWizardConsts';
import routes from '../../const/routes';
import useImpersonate from '../../hooks/useImpersonate';
import { useFetch } from '../../hooks/useFetch';
import useMutation from '../../hooks/useMutation';
import {
  convertFormValuesToPostData,
  copyWidgetCode,
  formatDataForCtaWidgetInitialValues,
  getProductsFromFormValues,
  isProductsTemplate,
  validateProductImages,
} from '../../util/CtaWizardUtil/CtaWizardUtil';
import Loading from '../Loading/Loading';
import ErrorPopupResult from '../Popups/ErrorPopupResult';
import CtaWizardPreview from './CtaWizardPreview';
import Step1Form from './StepForms/Step1Form';
import Step2Form from './StepForms/Step2Form/Step2Form';
import Step2ProductsForm from './StepForms/Step2Form/Step2ProductsForm';
import Step3Form from './StepForms/Step3Form';
import WizardSuccessResult from './WizardSuccessResult';
import { NotificationContext } from '../../context/NotificationContext';
import RouterPrompt from '../RouterPrompt/RouterPrompt';

const StyledFooter = styled.div`
  display: flex;
  width: auto;
  justify-content: space-between;
  gap: 32px;
`;

const StyledCtaWizard = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export default function CtaWizard() {
  const params = useParams();
  const [widgetId, setWidgetId] = useState(params.id || '');
  const [formValues, setFormValues] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [postErrorPopUpMessage, setPostErrorPopupMessage] = useState('');
  const [fetchErrorPopUpMessage, setFetchErrorPopupMessage] = useState('');
  const [productAmount, setProductAmount] = useState(1);
  const [showPrompt, setShowPrompt] = useState(false);

  const impersonateUrl = useImpersonate();

  const editMode = !!params.id;

  const [updateWidget] = useMutation({
    url: impersonateUrl(`/api/v1/widgets/${widgetId}`),
    method: 'put',
  });

  const { data: widgetData, error: widgetError } = useFetch({
    url: editMode ? impersonateUrl(`/api/v1/widgets/${widgetId}`) : null,
  });

  useEffect(() => {
    if (!editMode) {
      setFormValues(emptyWidgetInitialValues);
    } else {
      setCurrentStep(1);
      setShowPrompt(true);
    }
  }, []);

  useEffect(() => {
    if (widgetData) {
      const formValues = formatDataForCtaWidgetInitialValues(widgetData.item);
      const products = getProductsFromFormValues(formValues);
      setProductAmount(products.length);
      if (!formValues) {
        setFetchErrorPopupMessage('CTA data is corrupted');
      } else {
        setFormValues(formValues);
      }
    }
  }, [widgetData]);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [createWidget] = useMutation({ url: impersonateUrl('/api/v1/widgets'), method: 'post' });
  const { notificationApi, notificationNode } = useContext(NotificationContext);
  const template = formValues ? formValues.template : TEMPLATE_1;

  useEffect(() => {
    if (!editMode) {
      setFormValues((values) => ({
        ...values,
        headlineColor:
          values.template === TEMPLATE_1
            ? ctaWizardDefaultColors.black
            : ctaWizardDefaultColors.blue,
      }));
    }
  }, [template]);

  if (widgetError || fetchErrorPopUpMessage) {
    return (
      <ErrorPopupResult
        close={() => {
          setFetchErrorPopupMessage(null);
          navigate(routes.CTA_LIST);
        }}
        title={'Error occurred while retrieving the widget.'}
        message={widgetError?.message || fetchErrorPopUpMessage}
      />
    );
  }

  if (!formValues) {
    return <Loading />;
  }

  function getIsBackButtonDisabled() {
    if (editMode && currentStep === 1) {
      return true;
    }
    if (!editMode && currentStep === 0) {
      return true;
    }
    return false;
  }
  const onFinishFailed = (errorInfo) => {
    alert(JSON.stringify(errorInfo));
  };

  function nextStep() {
    if (!showPrompt) {
      setShowPrompt(true);
    }
    if (currentStep === 1 && !formValues.fileList?.length) {
      message.error('Upload an image to continue');
      return;
    }
    if (currentStep < 2) {
      setCurrentStep((step) => step + 1);
    }
    if (currentStep === 2) {
      postForm();
    }
  }

  function nextStepProductTemplateStep2() {
    const products = getProductsFromFormValues(formValues);
    validateProductImages({ products });
    setCurrentStep((v) => v + 1);
  }

  function prevStep() {
    if (currentStep > 0 && !editMode) {
      setCurrentStep((step) => step - 1);
    } else if (currentStep > 1) {
      setCurrentStep((step) => step - 1);
    }
  }

  async function postForm() {
    const body = convertFormValuesToPostData(formValues);
    try {
      let response;
      if (editMode) {
        response = await updateWidget({ body });
      } else {
        response = await createWidget({ body });
      }
      setShowPrompt(false);
      setWidgetId(response.item.id);
      setSuccessPopUp(true);
    } catch (error) {
      setPostErrorPopupMessage(error.message);
    }
  }

  return (
    <StyledCtaWizard className="cta-wizard">
      <RouterPrompt isOpen={showPrompt} />
      {notificationNode}
      {successPopUp && (
        <WizardSuccessResult
          close={() => {
            setSuccessPopUp(false);
            navigate(routes.CTA_LIST);
          }}
          copyCode={() => {
            copyWidgetCode(widgetId);
            notificationApi.info({
              message: 'The CTA code has been successfully copied!',
              placement: 'bottomRight',
              icon: <CheckCircleFilled style={{ color: '#52C41A' }} />,
            });
          }}
          editMode={editMode}
        />
      )}
      {postErrorPopUpMessage && (
        <ErrorPopupResult
          close={() => {
            setPostErrorPopupMessage(null);
          }}
          title={'Error occurred while creating CTA widget.'}
          message={postErrorPopUpMessage + ' You may try to change creative name!'}
        />
      )}
      <Layout.Content
        style={{
          padding: '24px 24px',
          display: 'flex',
          gap: '24px',
          overflowY: 'scroll',
        }}
      >
        <>
          {currentStep == 0 && (
            <Step1Form
              form={form}
              formValues={formValues}
              onFinish={nextStep}
              onFinishFailed={onFinishFailed}
              setFormValues={setFormValues}
            />
          )}
          {currentStep == 1 && !isProductsTemplate(template) && (
            <Step2Form
              form={form}
              formValues={formValues}
              onFinish={nextStep}
              onFinishFailed={onFinishFailed}
              setFormValues={setFormValues}
            />
          )}
          {currentStep == 1 && isProductsTemplate(template) && (
            <Step2ProductsForm
              form={form}
              formValues={formValues}
              onFinish={nextStepProductTemplateStep2}
              onFinishFailed={onFinishFailed}
              setFormValues={setFormValues}
              productAmount={productAmount}
              setProductAmount={setProductAmount}
            />
          )}

          {currentStep == 2 && (
            <Step3Form
              form={form}
              formValues={formValues}
              productAmount={productAmount}
              onFinish={nextStep}
              onFinishFailed={onFinishFailed}
              setFormValues={setFormValues}
            />
          )}
        </>

        <CtaWizardPreview formValues={formValues} currentStep={currentStep} />
      </Layout.Content>

      <Layout.Footer style={{ background: 'white' }}>
        <StyledFooter>
          <div>
            <Button disabled={getIsBackButtonDisabled()} onClick={prevStep}>
              Back
            </Button>
          </div>

          <Steps
            size="small"
            current={currentStep}
            items={[
              {
                title: 'Template Library',
              },
              {
                title: 'Copy & Design',
              },
              {
                title: 'Creative Details',
              },
            ]}
            style={{
              maxWidth: '640px',
            }}
          />

          {currentStep === 2 && (
            <Button type="primary" htmlType={'submit'} onClick={() => form.submit()}>
              Publish
            </Button>
          )}
          {currentStep === 1 && (
            <Button type="primary" htmlType={'submit'} onClick={() => form.submit()}>
              Next
            </Button>
          )}
          {currentStep === 0 && (
            <Button type="primary" htmlType={'submit'} onClick={() => form.submit()}>
              Next
            </Button>
          )}
        </StyledFooter>
      </Layout.Footer>
    </StyledCtaWizard>
  );
}
