import { Button, Result } from 'antd';
import styled from 'styled-components';
import CelebrationIcon from '../../assets/CelebrationIcon';
const StyledResult = styled.div`
  position: fixed;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  z-index: 100;
  top: 0px;
  left: 0px;
`;

export default function CelebrationPopupResult({ title, close, message = '' }) {
  function handleClose() {
    close();
  }

  return (
    <StyledResult>
      <Result
        style={{
          background: 'white',
          borderRadius: '2px',
        }}
        icon={<CelebrationIcon />}
        title={title}
        subTitle={message}
        extra={
          <Button type="primary" key="console" onClick={handleClose}>
            Got it!
          </Button>
        }
      />
    </StyledResult>
  );
}
