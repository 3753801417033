import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import routes from '../../const/routes';

function ProtectedRoute({ children }) {
  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    return <Navigate to={routes.LOGIN} replace />;
  }

  return children;
}

export default ProtectedRoute;
