import { CopyOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import styled from 'styled-components';

const StyledResult = styled.div`
  position: fixed;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  z-index: 100;
  top: 0px;
  left: 0px;
`;
export default function WizardSuccessResult({ close, copyCode, editMode }) {
  function handleClose() {
    close();
  }
  return (
    <StyledResult>
      <Result
        style={{
          background: 'white',
          borderRadius: '2px',
        }}
        status="success"
        title={`Your CTA has been successfully ${editMode ? 'updated' : 'created'}!`}
        subTitle={
          editMode
            ? 'Please allow up to 5 minutes for your edits to start populating '
            : 'Please allow up to 5 minutes to start populating or for edits to be deployed.'
        }
        extra={[
          <Button type="primary" key="console" onClick={handleClose}>
            Return Home
          </Button>,

          <Button key="buy" icon={<CopyOutlined />} onClick={copyCode}>
            Copy CTA code
          </Button>,
        ]}
      />
    </StyledResult>
  );
}
