import { Button, Popover } from 'antd';
import { useState } from 'react';

export default function ActionConfirmPopover({
  children,
  handleConfirm = () => {},
  handleReject = () => {},
  text = '',
  isConfirmButtonDanger = false,
  confirmText = 'Yes',
  rejectText = 'No',
}) {
  const [isPopoverShown, setIsPopoverShown] = useState(false);

  const closePopover = () => {
    setIsPopoverShown(false);
  };

  const onConfirmClick = () => {
    handleConfirm();
    closePopover();
  };

  const onRejectClick = () => {
    handleReject();
    closePopover();
  };

  return (
    <Popover
      content={
        <div style={{ display: 'flex', gap: '4px' }}>
          <Button danger={isConfirmButtonDanger} onClick={onConfirmClick} type="primary">
            {confirmText}
          </Button>
          <Button onClick={onRejectClick}>{rejectText}</Button>
        </div>
      }
      title={text}
      trigger="click"
      open={isPopoverShown}
      placement="leftTop"
      onOpenChange={(value) => {
        setIsPopoverShown(value);
      }}
    >
      {children}
    </Popover>
  );
}
