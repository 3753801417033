import { blue } from '@ant-design/colors';
import { LinkOutlined } from '@ant-design/icons';
import { Button, Modal, Tag } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

const StyledDomains = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
`;

export default function CtaTableDomainCell({ data }) {
  const { domains, name } = data;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [moreDomainsColor, setMoreDomainsColor] = useState(blue.primary);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title={`${name} CTA`}
        open={isModalOpen}
        footer={[
          <Button key={'Ok'} type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        <StyledDomains>
          {domains.map((domain) => (
            <Tag color={'blue'} key={domain}>
              <LinkOutlined />
              {domain}
            </Tag>
          ))}
        </StyledDomains>
      </Modal>
      <StyledDomains>
        {domains.slice(0, 4).map((domain) => (
          <Tag color={'blue'} key={domain}>
            <LinkOutlined />
            {domain}
          </Tag>
        ))}
        {domains.length > 4 && (
          <Tag
            onMouseOver={() => {
              setMoreDomainsColor(blue[4]);
            }}
            onMouseOut={() => {
              setMoreDomainsColor(blue.primary);
            }}
            style={{
              cursor: 'pointer',
            }}
            color={moreDomainsColor}
            onClick={showModal}
          >
            +{domains.length - 4}
          </Tag>
        )}
      </StyledDomains>
    </>
  );
}
