export default function ChartTab({
  backgroundColor,
  label,
  data,
  isFirstTab,
  boxShadow,
  color = 'white',
  borderBottom = 'none',
}) {
  return (
    <div
      style={{
        backgroundColor,
        color,
        borderBottom,
        boxShadow,
        borderTopLeftRadius: isFirstTab ? 'inherit' : 0,
        width: '180px',
        height: '100px',
        padding: '20px',
        display: 'inline-block',
      }}
    >
      <p style={{ fontSize: '12px', margin: 0 }}>{label}</p>
      <p style={{ fontSize: '25px', margin: 0, marginTop: '10px' }}>{data}</p>
    </div>
  );
}
