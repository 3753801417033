import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import InputLabel from '../Label/InputLabel';

const rangePresets = [
  { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
  { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs().add(-1, 'd')] },
  { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs().add(-1, 'd')] },
];

const disabledDate = (current) => {
  // can not select days after yesterday
  return current && current > dayjs().add(-1, 'd').endOf('day');
};

export default function RangePicker({
  label,
  startDate,
  endDate,
  setStartDate = () => {},
  setEndDate = () => {},
  size = 'large',
}) {
  return (
    <>
      <InputLabel text={label} />
      <DatePicker.RangePicker
        presets={rangePresets}
        style={{ width: '100%' }}
        size={size}
        value={[startDate, endDate]}
        disabledDate={disabledDate}
        allowClear={false}
        onChange={([startDate, endDate]) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
      />
    </>
  );
}
