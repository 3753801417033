import Paragraph from 'antd/es/typography/Paragraph';

export default function Headline(props) {
  return (
    <Paragraph
      {...props}
      style={{
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '32px',
        marginBottom: '4px',
        ...props.style,
      }}
    >
      {props.children}
    </Paragraph>
  );
}
