import { Form, InputNumber } from 'antd';

export default function RatingFormItem({ name, requiredMark }) {
  return (
    <Form.Item label="Rating" name={name} requiredMark={requiredMark}>
      <InputNumber
        min={0}
        max={5}
        step={0.1}
        placeholder="Enter a number between 0 and 5"
        precision={1}
        style={{ width: '100%' }}
        maxLength={10}
      />
    </Form.Item>
  );
}
