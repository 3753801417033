const {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} = require('react-router-dom');

import routes from './const/routes';
import ProtectedRoute from './util/ProtectedRoute/ProtectedRoute';
import MainLayout from './features/layouts/MainLayout/MainLayout';
import CtaList from './pages/CtaList/CtaListPage';
import PageNotFound from './pages/PageNotFound';
import Analytics from './pages/Analytics/AnalyticsPage';
import CtaWizard from './components/CtaWizard/CtaWizard';
import CtaListContextProvider from './context/CtaListContext';
import LoadingPage from './pages/Loading/LoadingPage';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<MainLayout />}>
        <Route exact path="/" element={<Navigate to={routes.CTA_LIST} />} />
        <Route
          path={routes.CTA_LIST}
          element={
            <ProtectedRoute>
              <CtaListContextProvider>
                <CtaList />
              </CtaListContextProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.ANALYTICS}
          element={
            <ProtectedRoute>
              <Analytics />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.CTA_WIZARD}
          element={
            <ProtectedRoute>
              <CtaWizard />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.CTA_WIZARD_EDIT}
          element={
            <ProtectedRoute>
              <CtaWizard />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path={routes.LOGOUT} element={<LoadingPage />} />
      <Route path="*" element={<PageNotFound />} />
    </>
  )
);

export default router;
