import { useAuth0 } from '@auth0/auth0-react';
import { message } from 'antd';

import { apiUrl, REQUEST_RETRY_TIMES } from '../const/const';
import getHttpErrorMessage from '../util/getHttpErrorMessage';
import makeHttpRequest from '../util/makeHttpRequest';
import { getAuth0AuthorizationParams } from './useAccessToken';

export default function useMutation({
  url: genericUrl,
  method: genericMethod,
  body: genericBody = null,
  headers: genericHeaders = {},
  baseUrl = apiUrl,
  isWithAccessToken = true,
}) {
  const { getAccessTokenSilently } = useAuth0();
  let retryCount = 0;

  const makeRequest = async ({
    url = genericUrl,
    method = genericMethod,
    body = genericBody,
    headers = genericHeaders,
  }) => {
    const options = { isWithAccessToken, baseUrl, url, method, body, headers };
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: getAuth0AuthorizationParams(),
      });
      const response = await makeHttpRequest({ ...options, accessToken });
      retryCount = 0;
      return response;
    } catch (error) {
      try {
        if (error?.response?.status !== 401) {
          throw new Error(getHttpErrorMessage(error));
        }

        if (retryCount < REQUEST_RETRY_TIMES) {
          retryCount++;
          return makeRequest(options);
        }

        throw new Error(getHttpErrorMessage(error));
      } catch (error) {
        message.error('Something went wrong! ' + error.message || '');
        throw new Error(error);
      }
    }
  };

  return [makeRequest];
}
