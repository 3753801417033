import { Divider, Layout } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';

import ErrorPopupResult from '../../components/Popups/ErrorPopupResult';
import { gtwApiUrl } from '../../const/const';
import { AdminAccountContext } from '../../context/AdminAccountContext';
import AnalyticsTable from '../../features/AnalyticsTable';
import { useFetch } from '../../hooks/useFetch';
import { composeAnalyticsUrl } from '../../util';
import AnalyticsChart from './AnalyticsChart';
import AnalyticsHeadingRow from './AnalyticsHeadingRow';
import AnalyticsSelectors from './AnalyticsSelectors';
import useAnalyticsWidgetData from './useAnalyticsWidgetData';

export default function AnalyticsPage() {
  const [startDate, setStartDate] = useState(dayjs().add(-90, 'd'));
  const [endDate, setEndDate] = useState(dayjs().add(-1, 'd'));
  const [selectedWidget, setSelectedWidget] = useState();
  const [isErrorPopupShown, setIsErrorPopupShown] = useState(false);

  const { selectedAdminAccount, isAdmin } = useContext(AdminAccountContext);

  const [getAnalytics, { data: chartData, isLoading: isAnalyticsLoading, error: chartError }] =
    useFetch({
      baseUrl: gtwApiUrl,
      isLazy: true,
    });

  const { widgetOptions, widgetError, isWidgetsLoading } = useAnalyticsWidgetData({
    selectedWidget,
    setSelectedWidget,
    selectedAdminAccount,
    isAdmin,
  });

  useEffect(() => {
    if (startDate && endDate && selectedWidget) {
      getAnalytics(composeAnalyticsUrl({ startDate, endDate, selectedWidget }));
    }
  }, [startDate, endDate, selectedWidget]);

  useEffect(() => {
    if ((widgetError || chartError) && !isErrorPopupShown) {
      setIsErrorPopupShown(true);
    }
  }, [widgetError, chartError]);

  if (isErrorPopupShown) {
    return (
      <ErrorPopupResult
        close={() => {
          setIsErrorPopupShown(false);
        }}
        title="Error occurred while trying to retrieve your statistics."
        message="Something went wrong. Please, try again later."
      />
    );
  }

  return (
    <div
      style={{
        overflowY: 'auto',
      }}
    >
      <Layout.Content style={{ margin: '24px' }}>
        <AnalyticsHeadingRow
          startDate={startDate}
          endDate={endDate}
          selectedWidget={selectedWidget}
        />
        <Divider style={{ marginBottom: '20px' }} />
        <AnalyticsSelectors
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          widgetOptions={widgetOptions}
          selectedWidget={selectedWidget}
          setSelectedWidget={setSelectedWidget}
          isWidgetsLoading={isWidgetsLoading}
        />
        <AnalyticsChart
          chartData={chartData?.total_by_date}
          tabsData={chartData?.total?.[0]}
          isLoading={isAnalyticsLoading}
        />
        <AnalyticsTable data={chartData} isLoading={isAnalyticsLoading} />
      </Layout.Content>
    </div>
  );
}
