import { Col, Row } from 'antd';

import RangePicker from '../../components/DatePicker/RangePicker';
import Selector from '../../components/Select';

export default function AnalyticsSelectors({
  widgetOptions,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  selectedWidget,
  setSelectedWidget,
  isWidgetsLoading,
}) {
  return (
    <Row style={{ marginBottom: '30px' }} gutter={16}>
      <Col span={8}>
        <Selector
          label="Select a CTA to view analytics"
          options={widgetOptions}
          value={selectedWidget}
          onChange={setSelectedWidget}
          isLoading={isWidgetsLoading}
          placeholder="Select widget"
        />
      </Col>
      <Col span={8}>
        <RangePicker
          label="Set Date Range"
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </Col>
    </Row>
  );
}
