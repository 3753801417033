import templateImg from '../../../assets/templates/templateOneImageAlt.png';
import { ctaWizardFields } from '../../../const/CtaWizardConsts';
import { VALIDATION_PATTERNS } from '../../../const/validationPatterns';
import { windowOpen } from '../../../util';
import {
  getGreyStars,
  getProductsFromFormValues,
  getYellowStars,
} from '../../../util/CtaWizardUtil/CtaWizardUtil';
import RepeatComponent from '../../RepeatComponent/RepeatComponent';
import './style.css';
import { CheckOutlined, StarFilled } from '@ant-design/icons';

const placeholder = {
  overallHeading: 'Our Favorites',
  img: templateImg,
  checklistItem1: 'Checklist item',
  checklistItem2: 'Checklist item',
  checklistItem3: 'Checklist item',
  checklistItem4: 'Checklist item',
  rating: '3.5',
  callToAction: 'Learn More',
  badgeTitle: 'Best Option',
  belowButtonText: 'Show more',
};

const getProductImage = (product) =>
  product?.fileList?.length > 0 ? product.fileList[0].url : null;

export default function TemplateFour({ data, width = 'auto', currentStep }) {
  const isStep1 = currentStep === 0;
  const products = getProductsFromFormValues(data);
  function handleClick(pageUrl) {
    if (pageUrl.match(VALIDATION_PATTERNS.URL)) {
      windowOpen(pageUrl, '_blank');
    }
  }

  return (
    <div style={{ width, maxWidth: '832px', margin: 'auto' }}>
      <div id="template-card-4" className="template-card">
        {(data[ctaWizardFields.overallHeading] || isStep1) && (
          <div className="overall-headline">
            {data[ctaWizardFields.overallHeading] || placeholder.overallHeading}
          </div>
        )}
        <div className="template-card-items">
          {products.map((product, index) => (
            <div key={index} style={{ position: 'relative' }}>
              {(product[ctaWizardFields.badgeTitle] || isStep1) && (
                <div
                  className="ribbon-container"
                  style={{ backgroundColor: data[ctaWizardFields.headlineColor] }}
                >
                  <div
                    className="ribbon"
                    style={{ backgroundColor: data[ctaWizardFields.headlineColor] }}
                  >
                    <span className="ribbon-text">
                      {product[ctaWizardFields.badgeTitle] || placeholder.badgeTitle}
                    </span>
                  </div>
                  <div
                    className="ribbon-corner"
                    style={{ color: data[ctaWizardFields.headlineColor] }}
                  />
                </div>
              )}

              <div key={product} className="template-card-item">
                <img
                  className="template-card-item__image"
                  src={getProductImage(product) || placeholder.img}
                  alt="image"
                />

                <div className="template-card-item__checklist">
                  <div className="template-card-item__checklist-item">
                    <CheckOutlined />{' '}
                    <span style={{ fontWeight: 900 }}>
                      {product[ctaWizardFields.checklistItem1] || placeholder.checklistItem1}
                    </span>
                  </div>
                  <div className="template-card-item__checklist-item">
                    <CheckOutlined />{' '}
                    <span>
                      {product[ctaWizardFields.checklistItem2] || placeholder.checklistItem2}
                    </span>
                  </div>
                  <div className="template-card-item__checklist-item">
                    <CheckOutlined />{' '}
                    <span>
                      {product[ctaWizardFields.checklistItem3] || placeholder.checklistItem3}
                    </span>
                  </div>
                  <div className="template-card-item__checklist-item">
                    <CheckOutlined />{' '}
                    <span>
                      {product[ctaWizardFields.checklistItem4] || placeholder.checklistItem4}
                    </span>
                  </div>
                </div>

                <div className="template-card-item__action">
                  {(product.rating || isStep1) && (
                    <div className="rating">
                      <div className="rating__stars">
                        <RepeatComponent repeatAmount={getYellowStars(product)}>
                          <StarFilled style={{ color: '#FFC107' }} />
                        </RepeatComponent>
                        <RepeatComponent repeatAmount={getGreyStars(product)}>
                          <StarFilled style={{ color: '#d9d9d9' }} />
                        </RepeatComponent>
                      </div>
                      <div className="rating__number">
                        {product[ctaWizardFields.rating]
                          ? product[ctaWizardFields.rating].toFixed(1)
                          : placeholder.rating}
                      </div>
                    </div>
                  )}
                  <button
                    className="template-button"
                    style={{
                      backgroundColor: data.buttonColor,
                      color: data.textButtonColor,
                      borderColor: data.buttonColor,
                    }}
                    onClick={() => handleClick(product.pageUrl)}
                  >
                    {product[ctaWizardFields.callToAction] || placeholder.callToAction}
                  </button>
                  {(product[ctaWizardFields.belowButtonText] || isStep1) && (
                    <div className="belowTextButton">
                      {product[ctaWizardFields.belowButtonText] || placeholder.belowButtonText}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
