import { Form, Select } from 'antd';

export default function TagsFormItem({
  name,
  label,
  rules,
  requiredMark,
  placeholder,
  maxTagCount,
}) {
  return (
    <Form.Item label={label} name={name} rules={rules} requiredMark={requiredMark}>
      <Select
        mode="tags"
        maxTagCount={maxTagCount}
        style={{
          width: '100%',
        }}
        placeholder={placeholder}
      />
    </Form.Item>
  );
}
