import { DownloadOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import { useEffect, useState } from 'react';

import Headline from '../../components/Headline';
import ErrorPopupResult from '../../components/Popups/ErrorPopupResult';
import ANALYTICS_FORMAT from '../../const/analyticsFormat';
import { gtwApiUrl } from '../../const/const';
import { useFetch } from '../../hooks/useFetch';
import { composeAnalyticsUrl, downloadFile } from '../../util';

export default function AnalyticsHeadingRow({ startDate, endDate, selectedWidget }) {
  const [isErrorPopupShown, setIsErrorPopupShown] = useState(false);
  const [getAnalyticsReport, { data: reportData, isLoading: isReportLoading, error: reportError }] =
    useFetch({
      baseUrl: gtwApiUrl,
      isLazy: true,
      isNoCache: true,
    });

  useEffect(() => {
    if (!isReportLoading && reportData) {
      const { url } = reportData;
      if (!url) {
        console.error('No report url is retrieved');
        setIsErrorPopupShown(true);
      } else {
        downloadFile(url);
      }
    }
  }, [isReportLoading]);

  if (isErrorPopupShown || reportError) {
    return (
      <ErrorPopupResult
        close={() => {
          setIsErrorPopupShown(false);
        }}
        title="Error occurred while trying to retrieve the report."
        message="Something went wrong. Please, try again later."
      />
    );
  }

  return (
    <Row justify="space-between">
      <Headline>Analytics</Headline>
      <Button
        type="text"
        icon={<DownloadOutlined />}
        style={{ fontWeight: 'bold' }}
        loading={isReportLoading}
        disabled={!startDate || !endDate || !selectedWidget}
        onClick={() => {
          const fetchUrl = composeAnalyticsUrl({
            startDate,
            endDate,
            selectedWidget,
            format: ANALYTICS_FORMAT.CSV,
          });
          getAnalyticsReport(fetchUrl);
        }}
      >
        Export CSV
      </Button>
    </Row>
  );
}
