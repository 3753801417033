import { Card, Form, Radio, Space } from 'antd';
import styled from 'styled-components';
import templateOneImg from '../../../assets/templates/t1skeleton.svg';
import templateTwoImg from '../../../assets/templates//t2skeleton.svg';
import templateThreeImg from '../../../assets/templates/t3skeleton.svg';
import templateFourImg from '../../../assets/templates/t4skeleton.svg';
import {
  ctaWidgetCardStyles,
  TEMPLATE_1,
  TEMPLATE_2,
  TEMPLATE_3,
  TEMPLATE_4,
} from '../../../const/CtaWizardConsts';

const StyledRatioItemTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const StyledRadioItemDescription = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  /* identical to box height, or 157% */

  /* Character/Secondary .45 */

  color: rgba(0, 0, 0, 0.45);
`;

const StyledCardContent = styled.div`
  display: grid;
  grid-template-columns: 140px 180px;
  gap: 16px;
  flex: 1;
  align-items: center;
  width: 320px;
  img {
    width: 140px;
  }
`;

const StyledCardContentTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;
export default function Step1Form({ form, formValues, onFinish, setFormValues }) {
  const selectedTemplate = formValues?.template || TEMPLATE_1;

  const radioItems = [
    {
      template: TEMPLATE_1,
      title: 'Clean & Simple',
      description: 'Minimalist design to display the product essentials and a short description.',
      image: templateOneImg,
    },
    {
      template: TEMPLATE_2,
      title: 'Pros & Cons',
      description: 'Include details, promotions, compare, and contrast the product.',
      image: templateTwoImg,
    },
    {
      template: TEMPLATE_3,
      title: 'Product Summary',
      description: 'Feature up to three different products with an engaging heading.',
      image: templateThreeImg,
    },
    {
      template: TEMPLATE_4,
      title: 'Badge & Features',
      description: 'List key features with check marks, a badge heading, and star rating.',
      image: templateFourImg,
    },
  ];
  return (
    <Form
      form={form}
      layout="vertical"
      name="cta-wizard"
      onFinish={onFinish}
      initialValues={formValues}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      onValuesChange={(updateItem) => {
        setFormValues((formVal) => ({ ...formVal, ...updateItem }));
      }}
    >
      <Card title="Select A Template From The Library" bordered={false} style={ctaWidgetCardStyles}>
        {
          <Form.Item name={'template'} required>
            <Radio.Group>
              <Space direction="vertical">
                {radioItems.map(({ template, image, title, description }) => {
                  return (
                    <Radio key={template} value={template} className="template-radio-card">
                      <Card
                        style={
                          selectedTemplate === template
                            ? {
                                border: '1px solid #1890FF',
                                boxShadow: ' 0px 0px 0px 2px rgba(24, 144, 255, 0.2)',
                              }
                            : {}
                        }
                      >
                        <StyledCardContent>
                          <img src={image} />
                          <StyledCardContentTextWrapper>
                            <StyledRatioItemTitle>{title}</StyledRatioItemTitle>
                            <StyledRadioItemDescription>{description}</StyledRadioItemDescription>
                          </StyledCardContentTextWrapper>
                        </StyledCardContent>
                      </Card>
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Form.Item>
        }
      </Card>
    </Form>
  );
}
