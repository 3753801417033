import { Button, Result, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

import routes from '../../const/routes';

export default function PageNotFound() {
  const navigate = useNavigate();

  function handleClick() {
    navigate(routes.CTA_LIST);
  }
  return (
    <Row
      justify="center"
      align="middle"
      style={{
        height: '100%',
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button onClick={handleClick} type="primary">
            Back Home
          </Button>
        }
      />
    </Row>
  );
}
