import { useEffect } from 'react';
import routes from '../../const/routes';

import { useFetch } from '../../hooks/useFetch';
import useImpersonate from '../../hooks/useImpersonate';

const useWidgetData = ({ selectedAdminAccount, isAdmin }) => {
  const impersonateUrl = useImpersonate();

  const [getWidgetsByUrl, { data: widgetData, error: widgetError, isLoading: isWidgetsLoading }] =
    useFetch({ isLazy: true });

  useEffect(() => {
    if (!isAdmin) {
      getWidgetsByUrl(routes.GET_WIDGETS_DATA);
    }

    if (isAdmin && selectedAdminAccount) {
      getWidgetsByUrl(impersonateUrl(routes.GET_WIDGETS_DATA));
    }
  }, [selectedAdminAccount]);

  return {
    widgetData,
    widgetError,
    isWidgetsLoading,
  };
};

export default useWidgetData;
