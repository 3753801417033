import { Card } from 'antd';
import templateImg from '../../../assets/templates/templateOneImageAlt.png';
import { ctaWizardFields } from '../../../const/CtaWizardConsts';
import { VALIDATION_PATTERNS } from '../../../const/validationPatterns';
import { windowOpen } from '../../../util';
import './style.css';

const placeholder = {
  headline: 'Key Value Proposition',
  brandName: 'Brand Name',
  productName: 'Product Name',
  callToAction: 'Learn More',
  prons: 'Pros',
  cons: 'Cons',
  img: templateImg,
  promo: 'Promotion/Coupon Code',
};
function formatPronsCons(arr) {
  if (arr >= 3) {
    return [...arr.slice(0, 3)];
  }
  const dashesArray = ['-', '-', '-'];
  return [...arr, ...dashesArray.slice(0, 3 - arr.length)];
}

const CheckCircleFilled = ({ fillColor = 'black' }) => (
  <svg width="12" height="12" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 0.25C7.30234 0.25 0.25 7.30234 0.25 16C0.25 24.6977 7.30234 31.75 16 31.75C24.6977 31.75 31.75 24.6977 31.75 16C31.75 7.30234 24.6977 0.25 16 0.25ZM22.8027 10.8566L15.3988 21.1223C15.2953 21.2667 15.1589 21.3844 15.0009 21.4656C14.8428 21.5468 14.6677 21.5891 14.49 21.5891C14.3124 21.5891 14.1372 21.5468 13.9792 21.4656C13.8211 21.3844 13.6847 21.2667 13.5813 21.1223L9.19727 15.0473C9.06367 14.8609 9.19727 14.6008 9.42578 14.6008H11.0746C11.4332 14.6008 11.7742 14.773 11.9852 15.0684L14.4883 18.5418L20.0148 10.8777C20.2258 10.5859 20.5633 10.4102 20.9254 10.4102H22.5742C22.8027 10.4102 22.9363 10.6703 22.8027 10.8566Z"
      fill={fillColor}
    />
  </svg>
);

const CloseCircleFilled = ({ fillColor = 'black' }) => (
  <svg width="12" height="12" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 0.25C7.30234 0.25 0.25 7.30234 0.25 16C0.25 24.6977 7.30234 31.75 16 31.75C24.6977 31.75 31.75 24.6977 31.75 16C31.75 7.30234 24.6977 0.25 16 0.25ZM21.8148 21.9836L19.4945 21.973L16 17.807L12.509 21.9695L10.1852 21.9801C10.0305 21.9801 9.90391 21.857 9.90391 21.6988C9.90391 21.632 9.92852 21.5688 9.9707 21.516L14.5445 16.0668L9.9707 10.6211C9.92822 10.5696 9.90465 10.5051 9.90391 10.4383C9.90391 10.2836 10.0305 10.157 10.1852 10.157L12.509 10.1676L16 14.3336L19.491 10.1711L21.8113 10.1605C21.966 10.1605 22.0926 10.2836 22.0926 10.4418C22.0926 10.5086 22.068 10.5719 22.0258 10.6246L17.459 16.0703L22.0293 21.5195C22.0715 21.5723 22.0961 21.6355 22.0961 21.7023C22.0961 21.857 21.9695 21.9836 21.8148 21.9836Z"
      fill={fillColor}
    />
  </svg>
);

export default function TemplateTwo({ data, width = 'auto' }) {
  const prons = formatPronsCons(data[ctaWizardFields.prons]);
  const cons = formatPronsCons(data[ctaWizardFields.cons]);
  const image = data?.fileList?.length > 0 ? data.fileList[0].url : null;

  function handleClick() {
    if (data?.pageUrl?.match(VALIDATION_PATTERNS.URL)) {
      windowOpen(data.pageUrl, '_blank');
    }
  }

  return (
    <Card
      bordered={false}
      style={{ width, maxWidth: '832px', margin: 'auto' }}
      bodyStyle={{ padding: 0 }}
    >
      <div id="template-card-2" className="template-card" data-url="url1">
        <div className="card-content">
          <div className="card-content__image-wrapper">
            <img className="card-content__image" src={image || placeholder.img} alt="image" />
          </div>
          <div className="card-content__aside">
            <div className="headline" style={{ color: data[ctaWizardFields.headlineColor] }}>
              {data[ctaWizardFields.headline] || placeholder.headline}
            </div>
            <div className="brand-name">{data[ctaWizardFields.brandName] || 'Brand Name'}</div>
            <div className="product-name">
              <span>{data[ctaWizardFields.productName] || 'Product Name'}</span>
            </div>
            {
              <div className="promo" style={{ color: data[ctaWizardFields.headlineColor] }}>
                {data[ctaWizardFields.promo]}
              </div>
            }
            <button
              className="template-button"
              style={{
                color: data[ctaWizardFields.textButtonColor],
                borderColor: data[ctaWizardFields.buttonColor],
                background: data[ctaWizardFields.buttonColor],
              }}
              onClick={handleClick}
            >
              {data[ctaWizardFields.callToAction] || 'Learn More'}
            </button>
          </div>
        </div>

        <div className="divider"></div>

        <div className="prons-cons-wrapper">
          <div className="prons-cons">
            <div className="prons-cons__title">
              {data[ctaWizardFields.pronsTitle] || placeholder.prons}
            </div>
            <div className="prons-cons__list">
              <div className="prons-cons__item">
                <CheckCircleFilled fillColor={data[ctaWizardFields.buttonColor]} />
                <span className="prons-cons__item__text">{prons[0]}</span>
              </div>
              <div className="prons-cons__item">
                <CheckCircleFilled fillColor={data[ctaWizardFields.buttonColor]} />

                <span className="prons-cons__item__text">{prons[1]}</span>
              </div>
              <div className="prons-cons__item">
                <CheckCircleFilled fillColor={data[ctaWizardFields.buttonColor]} />

                <span className="prons-cons__item__text">{prons[2]}</span>
              </div>
            </div>
          </div>

          <div className="prons-cons">
            <div className="prons-cons__title">
              {data[ctaWizardFields.consTitle] || placeholder.cons}
            </div>
            <div className="prons-cons__list">
              <div className="prons-cons__item">
                <CloseCircleFilled fillColor={data[ctaWizardFields.buttonColor]} />
                <span className="prons-cons__item__text">{cons[0]}</span>
              </div>
              <div className="prons-cons__item">
                <CloseCircleFilled fillColor={data[ctaWizardFields.buttonColor]} />

                <span className="prons-cons__item__text">{cons[1]}</span>
              </div>
              <div className="prons-cons__item">
                <CloseCircleFilled fillColor={data[ctaWizardFields.buttonColor]} />
                <span className="prons-cons__item__text">{cons[2]}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
