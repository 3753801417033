import React, { useState } from 'react';

import { useFetch } from '../hooks/useFetch';

export const AdminAccountContext = React.createContext();

export default function AdminAccountContextProvider({ children }) {
  const [selectedAdminAccount, setSelectedAdminAccount] = useState();

  const { data: myAccountData } = useFetch({ url: '/api/v1/accounts/me' });

  const isAdmin = myAccountData?.item?.role === 'ADMIN';

  return (
    <AdminAccountContext.Provider
      value={{
        selectedAdminAccount,
        setSelectedAdminAccount,
        isAdmin,
      }}
    >
      {children}
    </AdminAccountContext.Provider>
  );
}
