import axios from 'axios';

import { apiUrl } from '../const/const';

export default async function makeHttpRequest({
  accessToken,
  url,
  method,
  baseUrl = apiUrl,
  body = null,
  headers = {},
  isWithAccessToken = true,
}) {
  if (!accessToken && isWithAccessToken) {
    throw new Error('Access token is not provided');
  }
  const { data } = await axios({
    url: `${baseUrl}${url}`,
    method,
    data: body,
    headers: {
      Authorization: accessToken && isWithAccessToken ? `Bearer ${accessToken}` : undefined,
      'Content-Type': body ? 'application/json' : undefined,
      ...headers,
    },
  });
  return data;
}
