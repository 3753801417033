import {
  MAX_SELECT_TAGS_COUNT,
  TEMPLATE_3,
  TEMPLATE_4,
  ctaWizardFields,
  pageUrlPlaceholder,
  pageUrlRules,
} from '../../../const/CtaWizardConsts';
import { VALIDATION_PATTERNS } from '../../../const/validationPatterns';
import { getUploadHint, validateMaxSelectTags } from '../../../util/CtaWizardUtil/CtaWizardUtil';

const getChecklistItemConfig = (itemNumber) => {
  return {
    fieldType: FIELD_TYPE.INPUT,
    label: `Checklist Item ${itemNumber}`,
    placeholder: `Checklist Item ${itemNumber}`,
    maxLength: 60,
    rules: [
      {
        required: true,
        message: `Please input the checklist item ${itemNumber} to continue.`,
      },
    ],
  };
};

export const FIELD_TYPE = {
  INPUT: 'INPUT',
  TEXT_AREA: 'TEXT_AREA',
  TAGS: 'TAGS',
  RATING: 'RATING',
  COLOR_PICKERS: 'COLOR_PICKERS',
  IMAGE_UPLOADER: 'IMAGE_UPLOADER',
};

/**
 * Config for simple form fields used in the wizard
 * fieldType {FIELD_TYPE} - input type used to determine which form item component to use
 * label {string} - input label
 * placeholder {string} - input placeholder
 * maxLength {number} - input max length
 * requiredMark {boolean | 'optional'} - required mark style
 * rules {Array<RuleObject> (check antd documentation for more)} - list of validation rules
 * isShowCount {boolean} - boolean flag which determines if characters count should be shown (true by default)
 * maxTagCount {number} - max count of select tags (used only for TAGS field type)
 * title {string} - inputs title (used only for COLOR_PICKERS field type)
 * hint {string} - image uploader hint (used only for IMAGE_UPLOADER field type)
 */
export const WIZARD_FIELD_CONFIG = {
  [ctaWizardFields.badgeTitle]: {
    fieldType: FIELD_TYPE.INPUT,
    label: 'Badge Title',
    placeholder: 'Best Option',
    maxLength: 32,
    requiredMark: 'optional',
  },
  [ctaWizardFields.checklistItem1]: getChecklistItemConfig(1),
  [ctaWizardFields.checklistItem2]: getChecklistItemConfig(2),
  [ctaWizardFields.checklistItem3]: getChecklistItemConfig(3),
  [ctaWizardFields.checklistItem4]: getChecklistItemConfig(4),
  [ctaWizardFields.callToAction]: {
    fieldType: FIELD_TYPE.INPUT,
    label: 'Button Text',
    placeholder: 'Learn More, Next, View Details',
    maxLength: 25,
    rules: [
      {
        required: true,
        message: 'Please input a button text to continue.',
      },
    ],
  },
  [ctaWizardFields.callToActionPerProduct]: {
    fieldType: FIELD_TYPE.INPUT,
    name: ctaWizardFields.callToAction,
    label: 'Button Text',
    placeholder: 'Learn More, Next, View Details',
    maxLength: 25,
    rules: [
      {
        required: true,
        message: 'Please input the preferred copy for button text to continue.',
      },
    ],
  },
  [ctaWizardFields.belowButtonText]: {
    fieldType: FIELD_TYPE.INPUT,
    label: 'Below Button Text',
    placeholder: 'Below button text',
    maxLength: 20,
    requiredMark: 'optional',
  },
  [ctaWizardFields.headlineT2]: {
    fieldType: FIELD_TYPE.INPUT,
    name: ctaWizardFields.headline,
    label: 'Key Value Proposition',
    placeholder: 'Best For XYZ, Lowest Price, Special Offer',
    maxLength: 96,
    rules: [
      {
        required: true,
        message: 'Please input the key value proposition or preferred copy here.',
      },
    ],
  },
  [ctaWizardFields.headlineT3]: {
    fieldType: FIELD_TYPE.INPUT,
    name: ctaWizardFields.headline,
    label: 'Key Value Proposition',
    placeholder: 'Best For XYZ, Lowest Price, Promotional Offer',
    maxLength: 48,
    rules: [
      {
        required: true,
        message: 'Please input key value proposition or preferred copy to continue.',
      },
    ],
  },
  [ctaWizardFields.productNameT1]: {
    fieldType: FIELD_TYPE.INPUT,
    name: ctaWizardFields.headline,
    label: 'Product Name',
    placeholder: 'Include the product, brand name, or preferred heading here',
    maxLength: 96,
    rules: [
      {
        required: true,
        message: 'Please input the product name or preferred copy to continue.',
      },
    ],
  },
  [ctaWizardFields.productNameT2]: {
    fieldType: FIELD_TYPE.INPUT,
    name: ctaWizardFields.productName,
    label: 'Product Name',
    placeholder: 'Product name, Brand Name, Or Preferred Copy',
    maxLength: 25,
    rules: [
      {
        required: true,
        message: 'Please input the product name or preferred copy to continue.',
      },
    ],
  },
  [ctaWizardFields.productNameT3]: {
    fieldType: FIELD_TYPE.INPUT,
    name: ctaWizardFields.productName,
    label: 'Product Name',
    placeholder: 'Product, Brand Name, Product Type',
    maxLength: 48,
    rules: [
      {
        required: true,
        message: 'Please input the product name or preferred copy to continue.',
      },
    ],
  },
  [ctaWizardFields.descriptionT1]: {
    fieldType: FIELD_TYPE.TEXT_AREA,
    name: ctaWizardFields.description,
    label: 'Product Description',
    placeholder:
      'Include a description of the product and key benefits (tip: check the products landing page to see what details they highlight)',
    maxLength: 160,
    rules: [
      {
        required: true,
        message: 'Please input a description or preferred copy to continue.',
      },
    ],
  },
  [ctaWizardFields.descriptionT3]: {
    fieldType: FIELD_TYPE.TEXT_AREA,
    name: ctaWizardFields.description,
    label: 'Product Description',
    placeholder:
      'Key benefits (tip: check the products landing page to see what details they highlight and include them here)',
    maxLength: 64,
    rules: [
      {
        required: true,
        message: 'Please input the product description or preferred copy to continue.',
      },
    ],
  },
  [ctaWizardFields.overallHeading]: {
    fieldType: FIELD_TYPE.INPUT,
    label: 'Heading Summary',
    placeholder: 'Best Product Category of 2023, Editor Picks, Our Favorites',
    maxLength: 64,
    rules: [
      {
        required: true,
        message: 'Please input heading summary or preferred copy to continue.',
      },
    ],
  },
  [ctaWizardFields.overallHeadingOptional]: {
    fieldType: FIELD_TYPE.INPUT,
    name: ctaWizardFields.overallHeading,
    label: 'Heading Summary',
    placeholder: 'Best Product Category of 2023, Editor Picks, Our Favorites',
    maxLength: 64,
    requiredMark: 'optional',
  },
  [ctaWizardFields.smallDescription]: {
    fieldType: FIELD_TYPE.TEXT_AREA,
    label: 'Product Value Proposition',
    placeholder:
      'Include the most compelling value proposition for the product (e.g. Best For, Lowest Price, Promotional Offer)',
    maxLength: 72,
    rules: [
      {
        required: true,
        message: 'Please input the product value proposition or preferred copy to continue.',
      },
    ],
  },
  [ctaWizardFields.brandName]: {
    fieldType: FIELD_TYPE.INPUT,
    label: 'Brand Name',
    placeholder: 'Brand Name (or use this field for a product spec, product detail, etc.)',
    maxLength: 80,
    rules: [
      {
        required: true,
        message: 'Please input the brand name or preferred copy to continue.',
      },
    ],
  },
  [ctaWizardFields.promo]: {
    fieldType: FIELD_TYPE.INPUT,
    label: 'Promotion/Coupon Code',
    requiredMark: 'optional',
    placeholder: '20% Discount On All Orders: Code = ABC123, Buy 1 Get 1 Free',
    maxLength: 80,
  },
  [ctaWizardFields.pronsTitle]: {
    fieldType: FIELD_TYPE.INPUT,
    label: 'Pros',
    placeholder: 'Pros, What We Love, Product Specs, Custom Reviews, etc',
    maxLength: 20,
    rules: [{ required: true, message: 'Please input the pros or preferred copy to continue.' }],
  },
  [ctaWizardFields.prons]: {
    fieldType: FIELD_TYPE.TAGS,
    label: 'Pros List',
    placeholder: 'Pro or Description 1',
    rules: [
      {
        required: true,
        message: `Please input ${MAX_SELECT_TAGS_COUNT} pros or descriptions to continue.`,
      },
      { validator: validateMaxSelectTags },
    ],
  },
  [ctaWizardFields.consTitle]: {
    fieldType: FIELD_TYPE.INPUT,
    label: 'Cons',
    placeholder: 'Cons, What We Dislike, Similar Products, Expert Comments, etc',
    maxLength: 20,
    rules: [{ required: true, message: 'Please input the cons or preferred copy to continue.' }],
  },
  [ctaWizardFields.cons]: {
    fieldType: FIELD_TYPE.TAGS,
    label: 'Cons List',
    placeholder: 'Con or Description 1',
    rules: [
      {
        required: true,
        message: `Please input ${MAX_SELECT_TAGS_COUNT} cons or descriptions to continue.`,
      },
      { validator: validateMaxSelectTags },
    ],
  },
  [ctaWizardFields.creativeName]: {
    fieldType: FIELD_TYPE.INPUT,
    label: 'Creative Name',
    placeholder: 'Ex: Product Name Template Name Variant 1',
    rules: [{ required: true, message: 'Please input a creative name to continue.' }],
    isShowCount: false,
  },
  [ctaWizardFields.domains]: {
    fieldType: FIELD_TYPE.TAGS,
    label: 'Approved Websites',
    placeholder: 'site.com (CTA will only render on websites listed here)',
    rules: [
      { required: true, message: 'Please input at least 1 website name.' },
      {
        validator: (__, value) => {
          const areAllDomainsValid = value.every(
            (domain) => domain.match(VALIDATION_PATTERNS.DOMAIN)?.length > 1
          );
          if (areAllDomainsValid) {
            return Promise.resolve();
          }

          return Promise.reject('Some website names are not valid');
        },
        message: 'Validation Error (please remove http:// or https:// and try again).',
      },
    ],
    maxTagCount: 10,
  },
  [ctaWizardFields.pageUrl]: {
    fieldType: FIELD_TYPE.INPUT,
    label: 'Product Landing Page URL (affiliate tracking link)',
    placeholder: pageUrlPlaceholder,
    rules: pageUrlRules,
    isShowCount: false,
  },
  [ctaWizardFields.rating]: {
    fieldType: FIELD_TYPE.RATING,
    requiredMark: 'optional',
  },
  [ctaWizardFields.colorPickers]: {
    fieldType: FIELD_TYPE.COLOR_PICKERS,
  },
  [ctaWizardFields.colorPickersForProducts]: {
    fieldType: FIELD_TYPE.COLOR_PICKERS,
    title: 'Design',
  },
  [ctaWizardFields.imageUploader]: {
    fieldType: FIELD_TYPE.IMAGE_UPLOADER,
    label: 'Upload Your Image',
    hint: getUploadHint(),
  },
  [ctaWizardFields.imageUploaderT3]: {
    fieldType: FIELD_TYPE.IMAGE_UPLOADER,
    label: 'Upload Your Image',
    hint: getUploadHint(TEMPLATE_3),
  },
  [ctaWizardFields.imageUploaderT4]: {
    fieldType: FIELD_TYPE.IMAGE_UPLOADER,
    label: 'Upload Your Image',
    hint: getUploadHint(TEMPLATE_4),
  },
};
