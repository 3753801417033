import { Card } from 'antd';
import templateImg from '../../../assets/templates/templateOneImageAlt.png';
import { ctaWizardFields } from '../../../const/CtaWizardConsts';
import { VALIDATION_PATTERNS } from '../../../const/validationPatterns';
import { windowOpen } from '../../../util';
import './style.css';
import { getProductsFromFormValues } from '../../../util/CtaWizardUtil/CtaWizardUtil';

const placeholder = {
  overallHeading: 'Heading Summary',
  img: templateImg,
  headline: 'Product Value Proposition',
  productName: 'Product Name',
  description: ' Product Description',
  callToAction: 'Learn More',
};
export default function TemplateThree({ data, width = 'auto' }) {
  const products = getProductsFromFormValues(data);

  const getProductImage = (product) =>
    product?.fileList?.length > 0 ? product.fileList[0].url : null;

  function handleClick(pageUrl) {
    if (pageUrl.match(VALIDATION_PATTERNS.URL)) {
      windowOpen(pageUrl, '_blank');
    }
  }
  return (
    <Card
      bordered={false}
      style={{ width, maxWidth: '832px', margin: 'auto' }}
      bodyStyle={{ padding: 0 }}
    >
      <div id="template-card-3" className="template-card">
        <div className="overall-headline">
          {data[ctaWizardFields.overallHeading] || placeholder.overallHeading}
        </div>

        <div className="template-card-list">
          {products.map((product, index) => {
            const dataUrl = `url${index + 1}`;
            return (
              <>
                <div className="template-card-item" data-url={dataUrl}>
                  <img
                    className="template-card-item__image"
                    src={getProductImage(product) || placeholder.img}
                    alt="image"
                  />

                  <div className="template-card-item__info">
                    <div className="headline" style={{ color: data.headlineColor }}>
                      {product[ctaWizardFields.headline] || placeholder.headline}
                    </div>
                    <div className="productName">
                      <span>{product[ctaWizardFields.productName] || placeholder.productName}</span>
                    </div>
                    <div className="description">
                      {product[ctaWizardFields.description] || placeholder.description}
                    </div>
                  </div>

                  <div className="template-card-item__action">
                    <button
                      className="button"
                      style={{
                        backgroundColor: data.buttonColor,
                        color: data.textButtonColor,
                        borderColor: data.buttonColor,
                      }}
                      onClick={() => handleClick(product.pageUrl)}
                    >
                      {product[ctaWizardFields.callToAction] || placeholder.callToAction}
                    </button>
                  </div>
                </div>

                {index < products.length - 1 && <div className="divider"></div>}
              </>
            );
          })}
        </div>
      </div>
    </Card>
  );
}
