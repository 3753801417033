import { isNumber, unescape } from 'lodash';
import {
  AVAILABLE_PRODUCTS_ARRAY,
  ALLOWED_IMAGE_FORMATS,
  ctaWizardFields,
  emptyWidgetInitialValues,
  MAX_SELECT_TAGS_COUNT,
  TEMPLATE_1,
  TEMPLATE_2,
  TEMPLATE_3,
  TEMPLATE_4,
} from '../../const/CtaWizardConsts';
import { cdnUrl } from '../../const/const';
import { message } from 'antd';

export function removeProductFromFormValues(formValues, productIndex) {
  if (!isNumber(productIndex)) {
    throw Error('productIndex is incorrect type');
  }
  const products = getProductsFromFormValues(formValues);
  // remove 1
  products.splice(productIndex, 1);

  const newFormValues = AVAILABLE_PRODUCTS_ARRAY.reduce(
    (acc, productKey, idx) => {
      if (products[idx]) {
        return { ...acc, [productKey]: products[idx] };
      }

      delete acc[productKey];
      return acc;
    },
    { ...formValues }
  );

  return newFormValues;
}

export function getProductsFromFormValues(formValues) {
  const products = AVAILABLE_PRODUCTS_ARRAY.reduce((acc, productKey) => {
    if (formValues[productKey]) {
      return [...acc, formValues[productKey]];
    }

    return acc;
  }, []);

  return products;
}

function getCreativesForPostCTA(formValues) {
  const template = formValues.template;

  if (template === TEMPLATE_1) {
    const imagePath = formValues.fileList.length ? formValues.fileList[0].path : undefined;
    const imageUrl = formValues.fileList.length ? formValues.fileList[0].url : '';

    const creative = {
      headline: formValues.headline,
      image: imagePath,
      imageUrl: imageUrl,
      description: formValues.description,
      callToAction: formValues.callToAction,
      smallDescription: formValues.smallDescription,
      pageUrl: formValues.pageUrl,
    };
    return [creative];
  }
  if (template === TEMPLATE_2) {
    const imagePath = formValues.fileList.length ? formValues.fileList[0].path : undefined;
    const imageUrl = formValues.fileList.length ? formValues.fileList[0].url : '';

    const prons = formatPronsCons(formValues[ctaWizardFields.prons]);
    const cons = formatPronsCons(formValues[ctaWizardFields.cons]);
    const creative = {
      [ctaWizardFields.headline]: formValues[ctaWizardFields.headline],
      [ctaWizardFields.productName]: formValues[ctaWizardFields.productName],
      [ctaWizardFields.brandName]: formValues[ctaWizardFields.brandName],
      [ctaWizardFields.promo]: formValues[ctaWizardFields.promo],
      image: imagePath,
      imageUrl: imageUrl,
      [ctaWizardFields.callToAction]: formValues[ctaWizardFields.callToAction],

      [ctaWizardFields.pronsTitle]: formValues[ctaWizardFields.pronsTitle],
      [ctaWizardFields.prons]: prons,
      [ctaWizardFields.consTitle]: formValues[ctaWizardFields.consTitle],
      [ctaWizardFields.cons]: cons,

      [ctaWizardFields.pageUrl]: formValues.pageUrl,
    };
    return [creative];
  }

  if (template === TEMPLATE_3) {
    const products = getProductsFromFormValues(formValues);
    const creatives = products.map((product) => {
      const imagePath = product.fileList.length ? product.fileList[0].path : undefined;
      const imageUrl = product.fileList.length ? product.fileList[0].url : '';
      return {
        [ctaWizardFields.overallHeading]: formValues.overallHeading,
        image: imagePath,
        imageUrl: imageUrl,
        [ctaWizardFields.headline]: product[ctaWizardFields.headline],
        [ctaWizardFields.description]: product[ctaWizardFields.description],
        [ctaWizardFields.productName]: product[ctaWizardFields.productName],
        [ctaWizardFields.callToAction]: product[ctaWizardFields.callToAction],
        pageUrl: product.pageUrl,
      };
    });

    return [...creatives];
  }

  if (template === TEMPLATE_4) {
    const products = getProductsFromFormValues(formValues);
    const creatives = products.map((product) => {
      const imagePath = product.fileList.length ? product.fileList[0].path : undefined;
      const imageUrl = product.fileList.length ? product.fileList[0].url : '';
      return {
        [ctaWizardFields.overallHeading]: formValues.overallHeading,
        image: imagePath,
        imageUrl: imageUrl,
        [ctaWizardFields.badgeTitle]: product[ctaWizardFields.badgeTitle],
        [ctaWizardFields.checklistItem1]: product[ctaWizardFields.checklistItem1],
        [ctaWizardFields.checklistItem2]: product[ctaWizardFields.checklistItem2],
        [ctaWizardFields.checklistItem3]: product[ctaWizardFields.checklistItem3],
        [ctaWizardFields.checklistItem4]: product[ctaWizardFields.checklistItem4],
        [ctaWizardFields.rating]: product[ctaWizardFields.rating],
        [ctaWizardFields.callToAction]: product[ctaWizardFields.callToAction],
        [ctaWizardFields.belowButtonText]: product[ctaWizardFields.belowButtonText],
        pageUrl: product.pageUrl,
      };
    });

    return [...creatives];
  }
}

export function convertFormValuesToPostData(formValues) {
  const creatives = getCreativesForPostCTA(formValues);
  const { template } = formValues;
  const whiteLabel = {
    buttonColor: formValues.buttonColor,
    textButtonColor: formValues.textButtonColor,
    headlineColor: formValues.headlineColor,
  };

  const res = {
    name: formValues.creativeName,
    description: formValues.description,
    template,
    creatives,
    whiteLabel,
    htmlResult: 'not-used', // send dummy htmlResult for now.
    domains: formValues.domains,
  };
  return { ...res };
}

export function getWidgetCode(widgetId) {
  if (!widgetId) {
    return '';
  }
  return `
  <script src="${cdnUrl}scripts/widget.js" async></script>
  <div class="rvst" data-widget-id="${widgetId}"></div>
  `;
}

export function copyWidgetCode(widgetId) {
  const code = getWidgetCode(widgetId);
  navigator.clipboard.writeText(code);
}
export function copyText(text) {
  navigator.clipboard.writeText(text);
}

export function formatPronsCons(arr = []) {
  arr = arr.map((item) => item.trim());
  arr = arr.filter((item) => !!item);
  const dashesArray = ['-', '-', '-'];
  if (!arr) {
    return dashesArray;
  }
  if (arr.length >= 3) {
    return [...arr.slice(0, 3)];
  }
  return [...arr, ...dashesArray.slice(0, 3 - arr.length)];
}

function getFileListFromCreative(creative) {
  return {
    uid: '1',
    name: 'image.png',
    status: 'done',
    url: creative.imageUrl,
    imageUrl: creative.imageUrl,
    path: creative.image,
  };
}

export function formatDataForCtaWidgetInitialValues(data) {
  let result = {};
  const { template } = data;
  let ctaWizardValues = {
    ...emptyWidgetInitialValues,
    id: data.id,
    template,
    headlineColor: data.whiteLabel.headlineColor,
    buttonColor: data.whiteLabel.buttonColor,
    textButtonColor: data.whiteLabel.textButtonColor,
    creativeName: data.name,
    domains: data.domains,
  };

  // T1 or T2
  if (template === TEMPLATE_1 || template === TEMPLATE_2) {
    const [creative] = data.creatives;
    const fileList = [getFileListFromCreative(creative)];

    result = {
      ...ctaWizardValues,
      ...creative,
      fileList,
    };

    // T3 or T4
  } else if (isProductsTemplate(template)) {
    data.creatives.forEach((creative, index) => {
      const fileList = [getFileListFromCreative(creative)];

      ctaWizardValues = {
        ...ctaWizardValues,
        [`product${index + 1}`]: {
          ...creative,
          fileList,
        },
      };
    });

    result = {
      ...ctaWizardValues,
      overallHeading: data.creatives[0].overallHeading,
    };
  }

  // decode HTML back to text. eg. "1 &lt; 2 &amp; 3" => "1 < 2 & 3"
  loopThroughObjRecurs(result, function (object, key) {
    // skip numbers
    if (isNumber(object[key])) return;

    object[key] = unescape(object[key]);
  });

  return result;
}

function loopThroughObjRecurs(obj, funcForEachLeaf) {
  for (const k in obj) {
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      loopThroughObjRecurs(obj[k], funcForEachLeaf);
    } else if (k in obj) {
      funcForEachLeaf(obj, k);
    }
  }
}

export function validateMaxSelectTags(__, items) {
  if (items?.length > MAX_SELECT_TAGS_COUNT) {
    return Promise.reject(`Please enter no more than ${MAX_SELECT_TAGS_COUNT} items`);
  }
  return Promise.resolve();
}

export function isProductsTemplate(template) {
  return template === TEMPLATE_3 || template === TEMPLATE_4;
}

export function validateProductImages({ products }) {
  products.forEach((product, productIndex) => {
    if (!product[ctaWizardFields.fileList]) {
      const error = `Upload an image for the product ${productIndex + 1} to continue`;
      message.error(error);
      throw new Error(error);
    }
  });
}

export function getYellowStars(product) {
  return product.rating ? Math.round(product.rating) : 4;
}

export function getGreyStars(product) {
  return product.rating ? 5 - Math.round(product.rating) : 1;
}

const ALLOWED_IMAGE_FORMATS_NAMES = ALLOWED_IMAGE_FORMATS.map((i) => i.name);
export function getUploadHint(templateIndex) {
  let start;
  const end = `Allowed formats: ${ALLOWED_IMAGE_FORMATS_NAMES.join(
    ', '
  )}.\n Note: the image will not be resized.`;

  switch (templateIndex) {
    case TEMPLATE_3:
      start = `Recommended image size: 104x104 px.\n Note that the image should be square.\n`;
      break;
    case TEMPLATE_4:
      start = `Recommended image width is 204px.\n Recommended width to height ratio is 2:1.\n`;
      break;
    default:
      start = `Recommended image width is 485px.\n Recommended width to height ratio is 2:1.\n`;
  }
  return start + end;
}
