import { Row } from 'antd';

import Loading from '../../components/Loading/Loading';

export default function LoadingPage() {
  return (
    <Row align="middle" style={{ height: '100%' }}>
      <Loading />
    </Row>
  );
}
