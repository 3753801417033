import { unescape } from 'lodash';
import { useEffect } from 'react';

import routes from '../../const/routes';
import { useFetch } from '../../hooks/useFetch';
import useImpersonate from '../../hooks/useImpersonate';

const useAnalyticsWidgetData = ({
  selectedWidget,
  setSelectedWidget,
  selectedAdminAccount,
  isAdmin,
}) => {
  const impersonateUrl = useImpersonate();

  const [getWidgetsByUrl, { data: widgetData, isLoading: isWidgetsLoading, error: widgetError }] =
    useFetch({ isLazy: true });

  const widgetOptions =
    widgetData?.items?.map((i) => ({ value: i.id, label: unescape(i.name) })) || [];

  useEffect(() => {
    if (!isAdmin) {
      setSelectedWidget(null);
      getWidgetsByUrl(routes.GET_WIDGETS_DATA);
    }

    if (isAdmin && selectedAdminAccount) {
      setSelectedWidget(null);
      getWidgetsByUrl(impersonateUrl(routes.GET_WIDGETS_DATA));
    }
  }, [selectedAdminAccount]);

  useEffect(() => {
    if (widgetOptions?.length && !selectedWidget) {
      setSelectedWidget(widgetOptions[0].value);
    }
  }, [widgetOptions]);

  return {
    widgetOptions,
    widgetError,
    isWidgetsLoading,
  };
};

export default useAnalyticsWidgetData;
