import { DashboardTwoTone, HomeTwoTone, LogoutOutlined } from '@ant-design/icons';
import { Col, Layout, Menu, Row, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import routes from '../../../const/routes';
import logo from '../../../assets/logo.svg';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingPage from '../../../pages/Loading/LoadingPage';
import AdminAccountSelector from '../../AdminAccountSelector';

const { Header, Sider } = Layout;

const LogoImg = styled.img`
  margin: 0;
  height: 100%;
  width: fit-content;
`;

const StyledProfile = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const StyledName = styled.div`
  color: #ffffff;
`;
const StyledHeaderContent = styled.div`
  width: 100%;
`;
const StyledProfileImg = styled.img`
  width: 32px;
  height: 32px;
  background: #e6f4ff;
  border-radius: 100px;
`;
const StyledEmail = styled.div`
  font-weight: 400;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.45);
`;
const sidebarItems = [
  {
    key: routes.CTA_LIST,
    icon: React.createElement(HomeTwoTone),
    label: 'Home',
  },
  {
    key: routes.ANALYTICS,
    icon: React.createElement(DashboardTwoTone),
    label: 'Analytics',
  },
];

function MainLayout() {
  const { user, isLoading, logout } = useAuth0();
  const location = useLocation();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();

  const [selectedMenuItem, setSelectedMenuItem] = useState(location.pathname);

  useEffect(() => {
    if (location.pathname === routes.CTA_WIZARD) {
      document.title = 'OneCTA | Create CTA';
    } else if (location.pathname === routes.ANALYTICS) {
      document.title = 'OneCTA | Analytics';
    } else if (location.pathname === routes.CTA_WIZARD_EDIT) {
      document.title = 'OneCTA | Edit CTA';
    } else if (location.pathname === routes.CTA_LIST) {
      document.title = 'OneCTA | Creatives';
    }
    return () => {
      document.title = 'OneCTA';
    };
  }, [location]);

  function handleClickMenuItem(e) {
    setSelectedMenuItem(e.key);
    navigate(e.key);
  }

  function handleLogout() {
    logout({ logoutParams: { returnTo: `${window.location.origin}${routes.LOGOUT}` } });
  }

  if (isLoading) {
    return <LoadingPage />;
  }
  return (
    <Layout style={{ height: '100%' }}>
      <Header
        style={{
          display: 'flex',
          lineHeight: 'initial',
          alignItems: 'center',
          gap: 12,
        }}
      >
        <StyledHeaderContent>
          <Row justify="space-between" alignitems="center">
            <Row gutter={30}>
              <Col>
                <LogoImg src={logo} />
              </Col>
              <Col style={{ width: 400 }}>
                <AdminAccountSelector />
              </Col>
            </Row>
            <Col style={{ display: 'flex' }}>
              <StyledProfile>
                <StyledProfileImg src={user?.picture} />
                <div>
                  <StyledName>{user?.name}</StyledName>
                  <StyledEmail>{user?.email}</StyledEmail>
                </div>

                <LogoutOutlined
                  style={{
                    color: 'white',
                    padding: '14px 12px',
                  }}
                  onClick={handleLogout}
                />
              </StyledProfile>
            </Col>
          </Row>
        </StyledHeaderContent>
      </Header>
      <Layout>
        <Sider
          width={64}
          style={{
            background: colorBgContainer,
          }}
          trigger={null}
          collapsible
          collapsed={true}
        >
          <Menu
            defaultSelectedKeys={[routes.CTA_LIST]}
            style={{
              height: '100%',
              borderRight: 0,
            }}
            items={sidebarItems}
            onClick={handleClickMenuItem}
            selectedKeys={[selectedMenuItem]}
          />
        </Sider>
        <Layout
          style={{
            padding: '0',
            margin: 0,
            minHeight: 280,
          }}
        >
          <Outlet />
        </Layout>
      </Layout>
    </Layout>
  );
}
export default MainLayout;
