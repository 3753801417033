import { Form } from 'antd';
import { useState } from 'react';
import { PhotoshopPicker } from 'react-color';
import { BUTTON_COLOR, HEADLINE_COLOR, TEXT_BUTTON_COLOR } from '../../../../const/CtaWizardConsts';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { isWhiteColor } from '../../../../util';

const borderGreyColor = '#d9d9d9';

export const StyledSwatch = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledSwatchColor = styled.div`
  box-sizing: border-box;
  width: 90px;
  height: 32px;
  border-radius: 2px;
  background: ${(props) => props.color};
  border: ${({ color }) => (isWhiteColor(color) ? borderGreyColor : color)} 1px solid;
`;

export const StyledPhotoshopPickerWrapper = styled.div`
  position: relative;
  top: 10px;
  z-index: 1000;
`;

export const StyledCover = styled.div`
  position: absolute;
  left: 32px;
  ${({ bottomPosition }) => (bottomPosition ? 'top: -12px' : 'bottom: 32px')};
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
`;

export const StyledColorPickersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const colorFields = [
  { label: 'Heading Color', name: HEADLINE_COLOR },
  { label: 'Button Color', name: BUTTON_COLOR },
  { label: 'Button Text Color', name: TEXT_BUTTON_COLOR },
];

export default function ColorPickerFields({
  formValues,
  setFormValues,
  shownPicker,
  setShownPicker,
  bottomPosition = false,
}) {
  const { buttonColor, textButtonColor, headlineColor } = formValues;

  const [pickerColors, setPickerColors] = useState({
    buttonColor,
    textButtonColor,
    headlineColor,
  });
  function handleUpdatePickerColor(colorKey, colorVal) {
    setPickerColors((state) => ({ ...state, [colorKey]: colorVal }));
  }
  function closeColorPickers() {
    setShownPicker(null);
  }
  function handleAcceptColor(colorKey, colorVal) {
    setFormValues((state) => ({ ...state, [colorKey]: colorVal }));
    closeColorPickers();
  }
  return (
    <StyledColorPickersWrapper>
      {colorFields.map(({ label, name: COLOR_KEY }) => (
        <Form.Item key={COLOR_KEY} label={label}>
          <StyledSwatch onClick={() => setShownPicker(COLOR_KEY)}>
            <StyledSwatchColor color={formValues[COLOR_KEY]} />
            <StyledIconWrapper>
              <EditOutlined />
            </StyledIconWrapper>
          </StyledSwatch>

          {shownPicker === COLOR_KEY && (
            <StyledPhotoshopPickerWrapper>
              <StyledCover bottomPosition={bottomPosition}>
                <PhotoshopPicker
                  color={pickerColors[COLOR_KEY]}
                  onChange={(color) => handleUpdatePickerColor(COLOR_KEY, color.hex)}
                  onChangeComplete={(color) => handleUpdatePickerColor(COLOR_KEY, color.hex)}
                  onAccept={() => handleAcceptColor(COLOR_KEY, pickerColors[COLOR_KEY])}
                  onCancel={closeColorPickers}
                />
              </StyledCover>
            </StyledPhotoshopPickerWrapper>
          )}
        </Form.Item>
      ))}
    </StyledColorPickersWrapper>
  );
}
