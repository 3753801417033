import ChartTab from '../../components/Tab/ChartTab';
import { COLORS } from '../../const/colors';
import { formatBigNumber, formatPercent } from '../../util';

export default function Tabs({ data = {} }) {
  const clicks = formatBigNumber(data.click_count) || 0;
  const impressions = formatBigNumber(data.view_count) || 0;
  const ctr = data.ctr ? formatPercent(data.ctr * 100) : '0%';

  return (
    <>
      <ChartTab backgroundColor={COLORS.CLICKS} label="Total Clicks" data={clicks} isFirstTab />
      <ChartTab backgroundColor={COLORS.IMPRESSIONS} label="Total Views" data={impressions} />
      <ChartTab
        backgroundColor="white"
        color={COLORS.CTR}
        label="Average CTR"
        data={ctr}
        borderBottom="1px solid #E8E8E8"
        boxShadow="8px 0 10px -6px rgba(0, 0, 0, 0.15)"
      />
    </>
  );
}
