import { Button, Modal } from 'antd';
import ReactRouterPrompt from 'react-router-prompt';

const RouterPrompt = ({ isOpen }) => {
  return (
    <ReactRouterPrompt when={isOpen}>
      {({ isActive, onConfirm, onCancel }) => (
        <Modal
          title="You have unsaved changes"
          open={isActive}
          onCancel={onCancel}
          footer={[
            <Button key="No" onClick={onCancel}>
              No
            </Button>,
            <Button key="Yes" type="primary" onClick={onConfirm}>
              Yes
            </Button>,
          ]}
        >
          <p>Are you sure you want to leave this page? All unsaved changes will be lost.</p>
        </Modal>
      )}
    </ReactRouterPrompt>
  );
};

export default RouterPrompt;
