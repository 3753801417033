export default function RepeatComponent({ repeatAmount, children }) {
  return (
    <>
      {Array(repeatAmount)
        .fill(1)
        .map(() => {
          return children;
        })}
    </>
  );
}
